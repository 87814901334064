import { CircularProgress, IconButton, Paper, Select } from "@material-ui/core";
import React, { useEffect } from "react";
import SendIcon from "@material-ui/icons/Send";
import { useParams } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { useState } from "react";
import api from "../../services/api";
import { green } from "@material-ui/core/colors";
import toastError from "../../errors/toastError";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import { i18n } from "../../translate/i18n";

const useStyles = makeStyles((theme) => ({
  mainWrapper: {
    background: "#eee",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    borderTop: "1px solid rgba(0, 0, 0, 0.12)",
    [theme.breakpoints.down("sm")]: {
      position: "fixed",
      bottom: 0,
      width: "100%",
    },
    position: "relative",
    minHeight: "60px",
  },
  select: {
    width: "100%",
    background: "#fff",
    borderRadius: 20,
  },
  newMessageBox: {
    background: "#eee",
    width: "100%",
    display: "flex",
    padding: "7px",
    alignItems: "center",
  },
  sendMessageIcons: {
    color: "grey",
  },
  circleLoading: {
    color: green[500],
    opacity: "70%",
    position: "absolute",
    top: "20%",
    left: "50%",
    marginLeft: -12,
  },
  ticketClosed: {
    position: "absolute",
    transform: "translate(-50%, -50%)",
    top: "50%",
    left: "50%",
    color: "grey",
  }
}));

const MessageInputTemplate = ({ ticketStatus }) => {
  const classes = useStyles();
  const { ticketId } = useParams();
  const [loading, setLoading] = useState(true);
  const [templates, setTemplates] = useState([]);
  const [selectedTemplateName, setSelectedTemplateName] = useState("");

  const handleChange = (event) => {
    setSelectedTemplateName(event.target.value);
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(async () => {
      setLoading(true);
      try {
        const { data } = await api.get(`/templates`, {
          params: {
            status: "active",
            limit: 9999
          },
        });
        setTemplates(data.templates);
      } catch (err) {
        toastError(err);
      }

      setLoading(false);
    }, 1000);
    return () => clearTimeout(delayDebounceFn);
  }, []);

  const handleSendMessage = async () => {
    setLoading(true);

    const message = {
      template: {
        name: selectedTemplateName,
      },
    };
    try {
      await api.post(`/messages/template/${ticketId}`, message);
    } catch (err) {
      toastError(err);
    }

    setSelectedTemplateName("");
    setLoading(false);
  };

  return (
    <Paper elevation={0} square className={classes.mainWrapper}>
      <div className={classes.newMessageBox}>
        {ticketStatus === "closed" || ticketStatus === "pending" ? (
          <div className={classes.ticketClosed}>
            <p>{i18n.t("messagesInput.placeholderClosed")}</p>
          </div>
        ) : loading ? (
          <div>
            <CircularProgress className={classes.circleLoading} />
          </div>
        ) : (
          <>
            <FormControl fullWidth margin="dense" variant="outlined">
              <InputLabel>
                {i18n.t("messageInputTemplate.inputLabel")}
              </InputLabel>
              <Select
                labelWidth={180}
                value={selectedTemplateName || "No template"}
                className={classes.select}
                onChange={handleChange}
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                  },
                  getContentAnchorEl: null,
                }}
              >
                <MenuItem value="No template">
                  {i18n.t("messageInputTemplate.noTemplate")}
                </MenuItem>
                {templates.map((template) => (
                  <MenuItem key={template.id} value={template.name}>
                    {template.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <IconButton
              aria-label="sendMessage"
              component="span"
              onClick={handleSendMessage}
              disabled={loading || !selectedTemplateName}
            >
              <SendIcon className={classes.sendMessageIcons} />
            </IconButton>
          </>
        )}
      </div>
    </Paper>
  );
};

export default MessageInputTemplate;
