import React, { useEffect, useState } from "react";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import toastError from "../../errors/toastError";
import api from "../../services/api";
import InputLabel from "@material-ui/core/InputLabel";
import { i18n } from "../../translate/i18n";

const FarewellMessageTemplateSelect = ({ selectedTemplateName, onChange }) => {
  const [templates, setTemplates] = useState([]);
  const handleChange = (event) => {
    onChange(event.target.value);
  };

  useEffect(() => {
    (async () => {
      try {
        const { data } = await api.get(`/templates`, {
          params: {
            status: "active",
            limit: 9999
          },
        });
        setTemplates(data.templates);
      } catch (err) {
        toastError(err);
      }
    })();
  }, []);

  return (
    <FormControl fullWidth margin="dense" variant="outlined">
      <InputLabel>{i18n.t("farewellMessageTemplateSelect.inputLabel")}</InputLabel>
      <Select
        labelWidth={220}
        value={selectedTemplateName || "No template"}
        onChange={handleChange}
        MenuProps={{
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left",
          },
          transformOrigin: {
            vertical: "top",
            horizontal: "left",
          },
          getContentAnchorEl: null,
        }}
      >
        <MenuItem value="No template">{i18n.t("farewellMessageTemplateSelect.noTemplate")}</MenuItem>
        {templates.map((template) => (
          <MenuItem key={template.id} value={template.name}>
            {template.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default FarewellMessageTemplateSelect;
