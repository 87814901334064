import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { i18n } from "../../translate/i18n";
import { Form, Formik } from "formik";
import LabelSelect from "../LabelSelect";
import { makeStyles } from "@material-ui/core/styles";
import toastError from "../../errors/toastError";
import { toast } from "react-toastify";
import api from "../../services/api";

const useStyles = makeStyles((theme) => ({
  root: {},

  contactNumber: {
    color: theme.palette.primary.main,
    marginLeft: theme.spacing(1),
    fontSize: 14,
    fontStyle: "italic",
  },
}));

const LabelAssignContactModal = ({ open, onClose, contact }) => {
  const classes = useStyles();
  const [selectedLabelIds, setSelectedLabelIds] = useState([]);

  useEffect(() => {
    (async () => {
        if (contact && contact.labels) {
          setSelectedLabelIds(contact.labels.map((label) => label.id));
        }
    })();
  }, [contact]);

  const handleSaveLabel = async () => {
    try {
      await api.post("/labels/assign-contact", {
        contactId: contact.id,
        labelIds: selectedLabelIds,
      });
      toast.success("Label saved successfully");
      handleClose();
    } catch (err) {
      toastError(err);
    }
  };

  const handleClose = () => {
    onClose(false);
    setSelectedLabelIds([]);
  };

  return (
    <div className={classes.root}>
      <Dialog
        open={open}
        maxWidth="sm"
        fullWidth
        onClose={() => onClose(false)}
      >
        <DialogTitle>
          {i18n.t("labelAssignContactModal.title")}{" "}
          {contact?.name || contact?.number}{" "}
          <small className={classes.contactNumber}>({contact?.number})</small>
        </DialogTitle>
        <Formik
          initialValues={{
            selectedLabelIds: [],
          }}
          enableReinitialize={true}
          onSubmit={(values, actions) => {
            setTimeout(() => {
              handleSaveLabel(values);
              actions.setSubmitting(false);
            }, 400);
          }}
        >
          {() => (
            <Form>
              <DialogContent dividers>
                <LabelSelect
                  selectedLabelIds={selectedLabelIds}
                  onChange={(selectLabel) => setSelectedLabelIds(selectLabel)}
                />
              </DialogContent>
              <DialogActions>
                <Button
                  color="secondary"
                  variant="outlined"
                  onClick={handleClose}
                >
                  {i18n.t("labelAssignContactModal.buttons.cancel")}
                </Button>
                <Button type="submit" color="primary" variant="contained">
                  {i18n.t("labelAssignContactModal.buttons.ok")}
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
    </div>
  );
};

export default LabelAssignContactModal;
