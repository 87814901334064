import { Chip } from "@material-ui/core";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  messageReaction: {
    display: "flex",
    justifyContent: "flex-start",
    flexWrap: "wrap",
    gap: theme.spacing(1),
    "& > *": {
      marginTop: theme.spacing(1),
    },
  },
}));

const MessageReaction = ({ reaction }) => {
  const classes = useStyles();
  const reactionCount = reaction.reduce((count, r) => {
    count[r.reaction] = (count[r.reaction] || 0) + 1;
    return count;
  }, {});

  return (
    <>
      <div className={classes.messageReaction}>
        {Object.keys(reactionCount).map((key) => (
          <Chip size="small" key={key} label={`${key}: ${reactionCount[key]}`} />
        ))}
      </div>
    </>
  );
};

export default MessageReaction;
