import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  messageCtwaContext: {
    display: "flex",
    textDecoration: "none",
    color: "inherit",
  },

  messageCtwaContextContent: {
    display: "flex",
    flexDirection: "column",
    paddingLeft: 10,
    paddingTop: 5,
    backgroundColor: "rgba(255, 255, 255, 0.5)",
    borderTopRightRadius: 5,
    borderBottomRightRadius: 5,
  },

  messageCtwaContextThumbnail: {
    width: "65px",
    height: "100%",
    objectFit: "cover",
  },

  messageCtwaContextTitle: {
    fontWeight: 600,
    fontSize: 11,
  },

  messageCtwaContextDescription: {
    fontWeight: 100,
    fontStyle: "italic",
    fontSize: 10,
    display: "-webkit-box",
    "-webkit-line-clamp": 2,
    "-webkit-box-orient": "vertical",
    overflow: "hidden",
    // tablet
    [theme.breakpoints.down("md")]: {
      width: "250px",
    },
    // mobile
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
}));

const MessageCtwaContext = ({ context }) => {
  const classes = useStyles();

  return (
    <>
      {context && context.length > 0 && (
        <div>
          {context.map((item, index) => {
            return (
              <a href={item.sourceUrl} target="_blank" key={index} className={classes.messageCtwaContext}>
                <div>
                  <img
                    src={item.thumbnail}
                    alt="thumbnail"
                    className={classes.messageCtwaContextThumbnail}
                  />
                </div>
                <div className={classes.messageCtwaContextContent}>
                  <div className={classes.messageCtwaContextTitle}>{item.title}</div>
                  <div className={classes.messageCtwaContextDescription}>
                    {item.description}
                  </div>
                  <div className={classes.messageCtwaContextDescription}>
                    {item.sourceUrl}
                  </div>
                </div>
              </a>
            );
          })}
        </div>
      )}
    </>
  );
};

export default MessageCtwaContext;
