import { usePermissionsContext } from "../../context/Permission/PermissionsContext";

const Can = ({ role, perform, data, yes, no }) => {
  const { permissions } = usePermissionsContext();

  const check = (role, action, data, permissions) => {
    const rolePermissions = permissions[role];
    if (!rolePermissions) {
      // role is not present in the permissions
      return false;
    }

    const staticPermissions = rolePermissions.static;

    if (staticPermissions && staticPermissions.includes(action)) {
      // static rule not provided for action
      return true;
    }

    return false;
  };

  const canPerformAction = () => {
    if (!permissions) {
      // Permissions are still being fetched
      return false;
    }

    return check(role, perform, data, permissions);
  };

  return canPerformAction() ? yes() : no();
};

Can.defaultProps = {
  yes: () => null,
  no: () => null,
};

export { Can };
