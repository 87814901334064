import React, { createContext, useContext } from "react";
import usePermissions from "../../hooks/usePermissions";

const PermissionsContext = createContext();

export const PermissionsProvider = ({ children }) => {
  const { permissions, loading } = usePermissions();

  return (
    <PermissionsContext.Provider value={{ permissions, loading }}>
      {children}
    </PermissionsContext.Provider>
  );
};

export const usePermissionsContext = () => {
  return useContext(PermissionsContext);
};
