import React, { createContext, useContext } from "react";
import useSetting from "../../hooks/useSetting";

const SettingsContext = createContext();

export const SettingsProvider = ({ children }) => {
    const { loading, settings } = useSetting();

    return (
        <SettingsContext.Provider value={{ settings, loading }}>
            {children}
        </SettingsContext.Provider>
    );
};

export const useSettingsContext = () => {
    return useContext(SettingsContext);
}