function getConfig(name, defaultValue=null) {
    // If inside a docker container, use window.ENV
    if( window.ENV !== undefined ) {
        return window.ENV[name] || defaultValue;
    }

    return process.env[name] || defaultValue;
}

const defaultPath = `theme/${getConfig('REACT_APP_THEME')}`

export function getBackendUrl() {
    return getConfig('REACT_APP_BACKEND_URL');
}

export function getHoursCloseTicketsAuto() {
    return getConfig('REACT_APP_HOURS_CLOSE_TICKETS_AUTO') || 24;
}

export function getSandBoxIframe() {
    return getConfig('REACT_APP_SANDBOX_IFRAME') || 'allow-same-origin allow-scripts';
}

export function getChatBotUrl() {
    return getConfig('REACT_APP_CHATBOT_URL');
}

// For white labeling
export function getWhiteLabelLogo() {
    return getConfig('REACT_APP_WHITE_LABEL_LOGO') || `${defaultPath}/icon-waapy.png`;
}
export function getWhiteLabelName() {
    return getConfig('REACT_APP_WHITE_LABEL_NAME') || "Waapy Chat";
}
export function getWhiteLabelCompanyName() {
    return getConfig('REACT_APP_WHITE_LABEL_COMPANY_NAME') || "Bravo Net Solution";
}
export function getWhiteLabelCompanyUrl() {
    return getConfig('REACT_APP_WHITE_LABEL_COMPANY_URL') || "https://bravonet.digital";
}
export function getWhiteLabelImageBackground() {
    return getConfig('REACT_APP_WHITE_LABEL_IMAGE_BACKGROUND') || `${defaultPath}/waapy-chat-image-login.png`;
}
export function getWhiteLabelImageChat() {
    return getConfig('REACT_APP_WHITE_LABEL_IMAGE_CHAT') || `${defaultPath}/wa-background.png`;
}
export function getWhiteLabelLogoChatAi() {
    return getConfig('REACT_APP_WHITE_LABEL_LOGO_CHAT_AI') || `${defaultPath}/icon-waapy-chat-ai.png`;
}

// For status function
export function getChatBotStatus() {
    return getConfig('REACT_APP_CHATBOT_STATUS') || false;
}