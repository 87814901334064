import React, { useEffect, useState } from "react";

import * as Yup from "yup";
import { useHistory } from "react-router-dom";
import { Link as RouterLink } from "react-router-dom";
import { toast } from "react-toastify";
import { Formik, Form, Field } from "formik";

import {
	Avatar,
	Button,
	CssBaseline,
	TextField,
	Grid,
	Box,
	Typography,
	Container,
	InputAdornment,
	IconButton,
	Link
} from '@material-ui/core';

import { LockOutlined, Visibility, VisibilityOff } from '@material-ui/icons';

import { makeStyles } from "@material-ui/core/styles";

import { i18n } from "../../translate/i18n";

import api from "../../services/api";
import toastError from "../../errors/toastError";
import { getWhiteLabelLogo, getWhiteLabelName, getWhiteLabelCompanyName, getWhiteLabelCompanyUrl, getWhiteLabelImageBackground } from "../../config";

const useStyles = makeStyles(theme => ({
	root: {
		height: '100vh',
		background: 'linear-gradient(135deg, #E2F397 0%, #58BA89 100%)',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'stretch',
	},
	paper: {
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
	},
	bgSignup: {
		backgroundColor: '#fff',
		padding: '3rem',
		borderRadius: '10px',
		height: '100%',
		width: '100%',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
		[theme.breakpoints.down('sm')]: {
			padding: '1rem'
		}
	},
	bgSecond: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		height: '100%',
		padding: '3rem',
		borderRadius: '10px',
	},
	bgSecondImage: {
		width: '650px',
		backgroundSize: 'cover',
	},
	logo: {
		width: '150px',
		marginBottom: '2rem',
	},
	welcomeBack: {
		fontWeight: 'bold',
		marginBottom: '2rem',
		color: '#1E1E1E',
		fontSize: '35px'
	},
	footerText: {
		color: '#1E1E1E',
		fontSize: '12px',
		fontWeight: 'lighter',
	},
	avatar: {
		margin: theme.spacing(1),
		backgroundColor: theme.palette.secondary.main,
	},
	form: {
		width: "100%",
		marginTop: theme.spacing(3),
	},
	submit: {
		margin: theme.spacing(3, 0, 2),
	},
	hideOnMobile: {
		[theme.breakpoints.down('sm')]: {
			display: 'none',
		}
	}
}));

const UserSchema = Yup.object().shape({
	name: Yup.string()
		.min(2, "Too Short!")
		.max(50, "Too Long!")
		.required("Required"),
	password: Yup.string().min(5, "Too Short!").max(50, "Too Long!"),
	email: Yup.string().email("Invalid email").required("Required"),
});

const SignUp = () => {
	const classes = useStyles();
	const history = useHistory();

	const initialState = { name: "", email: "", password: "" };
	const [showPassword, setShowPassword] = useState(false);
	const [user] = useState(initialState);

	const handleSignUp = async values => {
		try {
			await api.post("/auth/signup", values);
			toast.success(i18n.t("signup.toasts.success"));
			history.push("/login");
		} catch (err) {
			toastError(err);
		}
	};

	useEffect(() => {
		const delayDebounceFn = setTimeout(() => {
			const getUserCreation = async () => {
			  try {
				const { data } = await api.get("/s/user-creation");
				
				if (data === "disabled") {
				  history.push("/login");
				}
			  } catch (err) {
				console.log(err);
			  }
			};
			getUserCreation();
		  }, 500);
		  return () => clearTimeout(delayDebounceFn);
	}, []);

	return (
		<Box component="span">
			<CssBaseline />
			<div className={classes.root}>
				<Grid container>
					<Grid container xs={12} md={6} lg={4} >
						<Container component="main" style={{paddingTop: '1.5rem', paddingBottom: '1.5rem'}}>
							<div className={classes.bgSignup}>
								<div className={classes.paper}>
									<img className={classes.logo} src={`/${getWhiteLabelLogo()}`} alt={`Logo ${getWhiteLabelName()}`}/>
									<Typography component="h1" variant="h5" className={classes.welcomeBack}>
										Register Now
									</Typography>
									<Formik
										initialValues={user}
										enableReinitialize={true}
										validationSchema={UserSchema}
										onSubmit={(values, actions) => {
											setTimeout(() => {
												handleSignUp(values);
												actions.setSubmitting(false);
											}, 400);
										}}
									>
										{({ touched, errors, isSubmitting }) => (
											<Form className={classes.form}>
												<Grid container spacing={2}>
													<Grid item xs={12}>
														<Field
															as={TextField}
															autoComplete="name"
															name="name"
															error={touched.name && Boolean(errors.name)}
															helperText={touched.name && errors.name}
															variant="outlined"
															fullWidth
															id="name"
															label={i18n.t("signup.form.name")}
															autoFocus
														/>
													</Grid>

													<Grid item xs={12}>
														<Field
															as={TextField}
															variant="outlined"
															fullWidth
															id="email"
															label={i18n.t("signup.form.email")}
															name="email"
															error={touched.email && Boolean(errors.email)}
															helperText={touched.email && errors.email}
															autoComplete="email"
														/>
													</Grid>
													<Grid item xs={12}>
														<Field
															as={TextField}
															variant="outlined"
															fullWidth
															name="password"
															id="password"
															autoComplete="current-password"
															error={touched.password && Boolean(errors.password)}
															helperText={touched.password && errors.password}
															label={i18n.t("signup.form.password")}
															type={showPassword ? 'text' : 'password'}
															InputProps={{
																endAdornment: (
																	<InputAdornment position="end">
																		<IconButton
																			aria-label="toggle password visibility"
																			onClick={() => setShowPassword((e) => !e)}
																		>
																			{showPassword ? <VisibilityOff /> : <Visibility />}
																		</IconButton>
																	</InputAdornment>
																)
															}}
														/>
													</Grid>
												</Grid>
												<Button
													type="submit"
													fullWidth
													variant="contained"
													color="primary"
													className={classes.submit}
												>
													{i18n.t("signup.buttons.submit")}
												</Button>
												<Grid container justifyContent="flex-start">
													<Grid item>
														<Link
															href="#"
															variant="body2"
															component={RouterLink}
															to="/login"
														>
															{i18n.t("signup.buttons.login")}
														</Link>
													</Grid>
												</Grid>
											</Form>
										)}
									</Formik>
								</div>
								<Box mt={8}>
									<Typography variant="body2" className={classes.footerText} align="center">
										{"Copyright © "}
										{new Date().getFullYear()}{". "}
										<Link color="inherit" href={getWhiteLabelCompanyUrl()}>
                                            {getWhiteLabelCompanyName()}
                                        </Link>{"."}
									</Typography>
								</Box>
							</div>
						</Container>
					</Grid>
					<Grid container className={classes.hideOnMobile} xs={12} md={6} lg={8}>
						<Container component="main" style={{paddingTop: '1.5rem', paddingBottom: '1.5rem'}}>
							<div className={classes.bgSecond}>
								<img src={`/${getWhiteLabelImageBackground()}`} alt="Background Login" className={classes.bgSecondImage}/>
							</div>
						</Container>
					</Grid>
				</Grid>
			</div>
		</Box>
	);
};

export default SignUp;
