const opacityColor = (color, percent) => {
  // color in hex format
  color = color.replace("#", "");
  const R = parseInt(color.substring(0, 2), 16);
  const G = parseInt(color.substring(2, 4), 16);
  const B = parseInt(color.substring(4, 6), 16);

  return `rgba(${R},${G},${B},${percent / 100})`;
}

export default opacityColor;
