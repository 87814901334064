import React, { useState, useReducer, useEffect } from "react";
import MainHeader from "../../components/MainHeader";
import Title from "../../components/Title";
import { i18n } from "../../translate/i18n";
import {
  Button,
  Container,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { DeleteOutline, Edit } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";
import IntegrationModal from "../../components/IntegrationModal";
import MainHeaderButtonsWrapper from "../../components/MainHeaderButtonsWrapper";
import api from "../../services/api";
import ConfirmationModal from "../../components/ConfirmationModal";
import toastError from "../../errors/toastError";
import openSocket from "../../services/socket-io";
import TableRowSkeleton from "../../components/TableRowSkeleton";

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
}));

const reducer = (state, action) => {
  if (action.type === "LOAD_INTEGRATIONS") {
    const integrations = action.payload;
    const newIntegrations = [];

    integrations.forEach((integration) => {
      const integrationIndex = state.findIndex((i) => i.id === integration.id);
      if (integrationIndex !== -1) {
        state[integrationIndex] = integration;
      } else {
        newIntegrations.push(integration);
      }
    });

    return [...state, ...newIntegrations];
  }

  if (action.type === "UPDATE_INTEGRATION") {
    const integration = action.payload;
    const integrationIndex = state.findIndex((i) => i.id === integration.id);
    if (integrationIndex !== -1) {
      state[integrationIndex] = integration;

      return [...state];
    } else {
      return [...state, integration];
    }
  }

  if (action.type === "DELETE_INTEGRATION") {
    const integrationId = action.payload;
    return state.filter((integration) => integration.id !== integrationId);
  }

  return state;
};

const Integrations = () => {
  const [integrations, dispatch] = useReducer(reducer, []);
  const classes = useStyles();
  const [integrationOpenModal, setIntegrationOpenModal] = useState(false);
  const [integrationId, setIntegrationId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);

  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        const response = await api.get("/integrations");
        dispatch({ type: "LOAD_INTEGRATIONS", payload: response.data });

        setLoading(false);
      } catch (err) {
        setLoading(false);
        toastError(err);
      }
    })();
  }, []);

  useEffect(() => {
    const socket = openSocket();

    socket.on("integration", (data) => {
      if (data.action === "update" || data.action === "create") {
        dispatch({ type: "UPDATE_INTEGRATION", payload: data.integration });
      }

      if (data.action === "delete") {
        dispatch({ type: "DELETE_INTEGRATION", payload: data.integrationId });
      }
    });

    return () => {
      socket.disconnect();
    };
  }, []);

  const handleOpenIntegrationModal = () => {
    setIntegrationOpenModal(true);
  };

  const handleEditIntegration = (integration) => {
    setIntegrationId(integration.id);
    setIntegrationOpenModal(true);
  };

  const handleCloseIntegrationModal = () => {
    setIntegrationId(null);
    setIntegrationOpenModal(false);
  };

  const handleClostConfirmModal = () => {
    setConfirmModalOpen(false);
    setIntegrationId(null);
  };

  const handleDeleteIntegration = () => {
    setLoading(true);

    try {
      api.delete(`/integrations/${integrationId}`);

      setConfirmModalOpen(false);
      setIntegrationId(null);
    } catch (err) {
      toastError(err);
    }

    setLoading(false);
  };

  return (
    <Container className={classes.container} maxWidth="lg">
      <IntegrationModal
        open={integrationOpenModal}
        integrationId={integrationId}
        onClose={() => handleCloseIntegrationModal()}
      />
      <ConfirmationModal
        open={confirmModalOpen}
        onClose={() => handleClostConfirmModal()}
        onConfirm={() => handleDeleteIntegration()}
        title={i18n.t("integrations.confirmationModal.deleteTitle")}
      >
        {i18n.t("integrations.confirmationModal.deleteMessage")}
      </ConfirmationModal>

      <MainHeader>
        <Title>{i18n.t("integrations.title")}</Title>
        <MainHeaderButtonsWrapper>
          <Button
            variant="contained"
            color="primary"
            onClick={handleOpenIntegrationModal}
          >
            {i18n.t("integrations.buttons.add")}
          </Button>
        </MainHeaderButtonsWrapper>
      </MainHeader>
      <Paper className={classes.container} variant="outlined">
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell align="left">
                {i18n.t("integrations.table.no")}
              </TableCell>
              <TableCell align="center">
                {i18n.t("integrations.table.type")}
              </TableCell>
              <TableCell align="center">
                {i18n.t("integrations.table.name")}
              </TableCell>
              <TableCell align="center">
                {i18n.t("integrations.table.actions")}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <>
              {integrations.map((integration, index) => (
                <TableRow key={index}>
                  <TableCell align="left">{index + 1}</TableCell>
                  <TableCell align="center">
                    {integration.type === "typebot"
                      ? "Waapy Bot"
                      : integration.type}
                  </TableCell>
                  <TableCell align="center">{integration.name}</TableCell>
                  <TableCell align="center">
                    <IconButton
                      size="small"
                      onClick={() => handleEditIntegration(integration)}
                    >
                      <Edit />
                    </IconButton>

                    <IconButton
                      size="small"
                      onClick={() => {
                        setConfirmModalOpen(true);
                        setIntegrationId(integration.id);
                      }}
                    >
                      <DeleteOutline />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
              {loading && <TableRowSkeleton />}
            </>
          </TableBody>
        </Table>
      </Paper>
    </Container>
  );
};

export default Integrations;
