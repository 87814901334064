import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";

//TO-DO set up firebase config
const firebaseConfig = {
  apiKey: "AIzaSyBmPA--opgk64HEwiS9puxau-T8zO62DJ0",
  authDomain: "waapy-chat-push-notification.firebaseapp.com",
  projectId: "waapy-chat-push-notification",
  storageBucket: "waapy-chat-push-notification.appspot.com",
  messagingSenderId: "546471860836",
  appId: "1:546471860836:web:ad16fe1963a63c7e179d71"
};

const firebaseApp = initializeApp(firebaseConfig);
const messaging = getMessaging(firebaseApp);

export const fetchToken = (setTokenFound, token, error) => {
  return getToken(messaging, {
    //TO-DO set vapidkey
    vapidKey: "BCW1D5lrXA207lDygoke--MkEzGcy1tsjK01M8qvNCsZS_0mN-4ER0D5nNuMX_OuqiGwIwIbnFBsKU5oQp-L1rM"
  })
    .then(async (currentToken) => {
      if (currentToken) {
        token(currentToken);
        setTokenFound(true);
        // Track the token -> client mapping, by sending to backend server
        // show on the UI that permission is secured
      } else {
        console.log(
          "No registration token available. Request permission to generate one."
        );
        error("No registration token available.");
        setTokenFound(false);
        // shows on the UI that permission is required
      }
    })
    .catch((err) => {
      console.log("An error occurred while retrieving token. ", err);
      error("An error occurred while retrieving token.");
      // catch error while creating client token
    });
};

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
  });
