import { useEffect, useState } from "react";
import api from "../../services/api";

const usePermissions = () => {
  const [loading, setLoading] = useState(true);
  const [permissions, setPermissions] = useState([]);

  useEffect(() => {
    const fetchPermissions = async () => {
      setLoading(true);
  
      const { data } = await api.get("/permission");
  
      setPermissions(data);
      setLoading(false);
    };

    
    fetchPermissions();
  }, []);

  return { loading, permissions };
};

export default usePermissions;
