import { useState, useEffect, useCallback } from "react";
import toastError from "../../errors/toastError";
import api from "../../services/api";
import openSocket from "../../services/socket-io";

const useTickets = ({
  searchParam,
  pageNumber,
  status,
  date,
  showAll,
  queueIds,
  labelIds,
  withUnreadMessages,
}) => {
  const [loading, setLoading] = useState(true);
  const [hasMore, setHasMore] = useState(false);
  const [tickets, setTickets] = useState([]);
  const [count, setCount] = useState(0);

  const fetchTickets = useCallback(async () => {
    setLoading(true);
    try {
      const { data } = await api.get("/tickets", {
        params: {
          searchParam,
          pageNumber,
          status,
          date,
          showAll,
          queueIds,
          labelIds,
          withUnreadMessages,
        },
      });
      setTickets(data.tickets);
      setHasMore(data.hasMore);
      setCount(data.count);
    } catch (err) {
      toastError(err);
    } finally {
      setLoading(false);
    }
  }, [
    searchParam,
    pageNumber,
    status,
    date,
    showAll,
    queueIds,
    labelIds,
    withUnreadMessages,
  ]);

  useEffect(() => {
    const socket = openSocket();

    const handleVisibilityChange = () => {
      if (!document.hidden) {
        console.log("Page is visible again");
        fetchTickets();
        socket.disconnect();
        openSocket();
      }
    };

    const handleFocus = () => {
      console.log("Window regained focus");
      const delayDebounceFn = setTimeout(() => {
        fetchTickets();
      }, 500);

      return () => clearTimeout(delayDebounceFn);
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);
    window.addEventListener("focus", handleFocus);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
      window.removeEventListener("focus", handleFocus);
      socket.disconnect();
    };
  }, [fetchTickets, openSocket]);

  // Periodic check
  useEffect(() => {
    const intervalId = setInterval(() => {
      console.log("Periodic check");
      const delayDebounceFn = setTimeout(() => {
        fetchTickets();
      }, 500);

      return () => clearTimeout(delayDebounceFn);
    }, 60000); // Check every minute

    return () => clearInterval(intervalId);
  }, [fetchTickets]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      fetchTickets();
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [fetchTickets]);

  return { tickets, loading, hasMore, count };
};

export default useTickets;
