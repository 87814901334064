import React, { useContext, useState } from "react";
import { useHistory } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";
import { IconButton, Typography } from "@material-ui/core";
import { MoreVert, Replay, NoteAdd } from "@material-ui/icons";

import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import TicketOptionsMenu from "../TicketOptionsMenu";
import ButtonWithSpinner from "../ButtonWithSpinner";
import toastError from "../../errors/toastError";
import { AuthContext } from "../../context/Auth/AuthContext";
import NoteDrawer from "../NoteDrawer";
import { Can } from "../Can";

const useStyles = makeStyles((theme) => ({
  actionButtons: {
    marginRight: 6,
    flex: "none",
    alignSelf: "center",
    marginLeft: "auto",
    "& > *": {
      margin: theme.spacing(1),
    },

    [theme.breakpoints.down("xs")]: {
      // mobile
      marginRight: 0,
      marginLeft: "auto",
      "& > *": {
        margin: theme.spacing(0.5),
      },
    },
  },

  textActionButtons: {
    fontSize: 14,

    [theme.breakpoints.down("xs")]: {
      // mobile
      fontSize: 12,
    },
  },
}));

const TicketActionButtons = ({ ticket }) => {
  const classes = useStyles();
  const history = useHistory();
  const [anchorEl, setAnchorEl] = useState(null);
  const [loading, setLoading] = useState(false);
  const ticketOptionsMenuOpen = Boolean(anchorEl);
  const { user } = useContext(AuthContext);

  const [drawerOpen, setDrawerOpen] = useState(false);

  const handleOpenTicketOptionsMenu = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const handleCloseTicketOptionsMenu = (e) => {
    setAnchorEl(null);
  };

  const handleUpdateTicketStatus = async (e, status, userId) => {
    setLoading(true);
    try {
      await api.put(`/tickets/${ticket.id}`, {
        status: status,
        userId: userId || null,
        whatsappId: ticket.whatsappId,
      });

      setLoading(false);
      if (status === "open") {
        history.push(`/tickets/${ticket.id}`);
      } else {
        history.push("/tickets");
      }
    } catch (err) {
      setLoading(false);
      toastError(err);
    }
  };

  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  return (
    <div className={classes.actionButtons}>
      <ButtonWithSpinner
        loading={loading}
        size="small"
        startIcon={<NoteAdd />}
        onClick={handleDrawerOpen}
      >
        <Typography variant="body2" className={classes.textActionButtons}>
          {i18n.t("messagesList.header.buttons.addNote")}{" "}
          {ticket?.notesCount > 0 && `(${ticket?.notesCount})`}
        </Typography>
      </ButtonWithSpinner>
      {ticket.status === "closed" && (
        <ButtonWithSpinner
          loading={loading}
          startIcon={<Replay />}
          size="small"
          onClick={(e) => handleUpdateTicketStatus(e, "open", user?.id)}
        >
          <Typography variant="body2" className={classes.textActionButtons}>
            {i18n.t("messagesList.header.buttons.reopen")}
          </Typography>
        </ButtonWithSpinner>
      )}
      {ticket.status === "open" && (
        <>
          <Can
            role={user.profile}
            perform="tickets:return-button"
            yes={() => (
              <ButtonWithSpinner
                loading={loading}
                startIcon={<Replay />}
                size="small"
                onClick={(e) => handleUpdateTicketStatus(e, "pending", null)}
              >
                <Typography
                  variant="body2"
                  className={classes.textActionButtons}
                >
                  {i18n.t("messagesList.header.buttons.return")}
                </Typography>
              </ButtonWithSpinner>
            )}
          />
          <Can
            role={user.profile}
            perform="tickets:resolve-button"
            yes={() => (
              <ButtonWithSpinner
                loading={loading}
                size="small"
                variant="contained"
                color="primary"
                onClick={(e) => handleUpdateTicketStatus(e, "closed", user?.id)}
              >
                <Typography
                  variant="body2"
                  className={classes.textActionButtons}
                >
                  {i18n.t("messagesList.header.buttons.resolve")}
                </Typography>
              </ButtonWithSpinner>
            )}
          />
          <IconButton onClick={handleOpenTicketOptionsMenu}>
            <MoreVert />
          </IconButton>
          <TicketOptionsMenu
            ticket={ticket}
            anchorEl={anchorEl}
            menuOpen={ticketOptionsMenuOpen}
            handleClose={handleCloseTicketOptionsMenu}
          />
        </>
      )}
      {ticket.status === "pending" && (
        <Can
          role={user.profile}
          perform="tickets:accept-button"
          yes={() => (
            <ButtonWithSpinner
              loading={loading}
              size="small"
              variant="contained"
              color="primary"
              onClick={(e) => handleUpdateTicketStatus(e, "open", user?.id)}
            >
              <Typography variant="body2" className={classes.textActionButtons}>
                {i18n.t("messagesList.header.buttons.accept")}
              </Typography>
            </ButtonWithSpinner>
          )}
        />
      )}
      <NoteDrawer
        open={drawerOpen}
        handleDrawerClose={handleDrawerClose}
        ticket={ticket}
        loading={loading}
      />
    </div>
  );
};

export default TicketActionButtons;
