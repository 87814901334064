import React, { useEffect, useState, useContext } from "react";

import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import Drawer from "@material-ui/core/Drawer";
import Button from "@material-ui/core/Button";
import parse from "html-react-parser";
import { i18n } from "../../translate/i18n";

import ContactDrawerSkeleton from "../ContactDrawerSkeleton";
import MarkdownWrapper from "../MarkdownWrapper";
import ConfirmationModal from "../ConfirmationModal";

import openSocket from "../../services/socket-io";
import { parseISO, format } from "date-fns";
import { toast } from "react-toastify";

import { AuthContext } from "../../context/Auth/AuthContext";
import api from "../../services/api";
import toastError from "../../errors/toastError";
import NoteModal from "../NoteModal";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Paper,
} from "@material-ui/core";
import { Visibility } from "@material-ui/icons";

const drawerWidth = "100%";

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    display: "flex",
    borderTop: "1px solid rgba(0, 0, 0, 0.12)",
    borderRight: "1px solid rgba(0, 0, 0, 0.12)",
    borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
    borderTopRightRadius: 4,
    borderBottomRightRadius: 4,
  },
  header: {
    display: "flex",
    borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
    backgroundColor: "#eee",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    minHeight: "73px",
    justifyContent: "flex-start",
  },
  content: {
    display: "flex",
    backgroundColor: "#eee",
    flexDirection: "column",
    padding: "8px 0px 8px 8px",
    height: "100%",
    overflowY: "scroll",
    ...theme.scrollbarStyles,
  },

  contactAvatar: {
    margin: 15,
    width: 160,
    height: 160,
  },

  contactHeader: {
    display: "flex",
    padding: 8,
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    "& > *": {
      margin: 4,
    },
  },

  contactDetails: {
    marginTop: 8,
    padding: 8,
    display: "flex",
    flexDirection: "column",
  },

  contactExtraInfo: {
    marginTop: 4,
    padding: 6,
  },

  iconButtonAccordion: {
    paddingTop: 0,
    paddingLeft: 2,
    paddingRight: 2,
    paddingBottom: 0,
  },
}));

const NoteDrawer = ({ open, handleDrawerClose, ticket, loading }) => {
  const classes = useStyles();

  const [notes, setNotes] = useState([]);
  const [note, setNote] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
  const { user } = useContext(AuthContext);

  useEffect(() => {
    const fecthNotes = async () => {
      try {
        const { data } = await api.get(`/notes`, {
          params: { ticketId: ticket.id },
        });
        setNotes(data.notes);
      } catch (err) {
        toastError(err);
      }
    };

    fecthNotes();
  }, [ticket]);

  useEffect(() => {
    const socket = openSocket();

    socket.on("note", (data) => {
      console.log(data);
      if (data.action === "create") {
        setNotes((prevState) => {
          const noteIndex = prevState.findIndex(
            (note) => note.id === data.note.id
          );

          if (noteIndex < 0) {
            // push to last position in the array list
            return [...prevState, data.note];
          } else {
            return prevState;
          }
        });
      } else if (data.action === "update") {
        setNotes((prevState) => {
          const noteIndex = prevState.findIndex(
            (note) => note.id === data.note.id
          );

          if (noteIndex >= 0) {
            return [
              ...prevState.slice(0, noteIndex),
              data.note,
              ...prevState.slice(noteIndex + 1),
            ];
          } else {
            return prevState;
          }
        });
      } else if (data.action === "delete") {
        setNotes((prevState) => {
          return prevState.filter((note) => note?.id !== data?.note?.id);
        });
      }
    });

    return () => {
      socket.disconnect();
    };
  }, []);

  const handleOpenModal = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setNote(null);
  };

  const handleConfirmDelete = (value) => {
    setConfirmDeleteOpen(true);
    setNote(value);
    console.log(value, "value");
  };

  const handleDeleteNote = async (value) => {
    try {
      await api.delete(`/notes/${value.id}`);
      setNotes((prevState) => prevState.filter((note) => note.id !== value.id));

      toast.success(i18n.t("noteDrawer.deleteNoteSuccess"));
    } catch (err) {
      toastError(err);
    }
  };

  const handleUpdateNote = (value) => {
    setModalOpen(true);
    setNote(value);
  };

  return (
    <Drawer
      className={classes.drawer}
      variant="persistent"
      anchor="right"
      open={open}
      PaperProps={{ style: { position: "absolute" } }}
      BackdropProps={{ style: { position: "absolute" } }}
      ModalProps={{
        container: document.getElementById("drawer-container"),
        style: { position: "absolute" },
      }}
      classes={{
        paper: classes.drawerPaper,
      }}
    >
      <ConfirmationModal
        title={i18n.t("noteDrawer.deleteNote") + " " + note?.title}
        open={confirmDeleteOpen}
        onClose={setConfirmDeleteOpen}
        onConfirm={() => handleDeleteNote(note)}
      >
        {i18n.t("noteDrawer.deleteNoteConfirm")}
      </ConfirmationModal>
      <div className={classes.header}>
        <IconButton onClick={handleDrawerClose}>
          <CloseIcon />
        </IconButton>
        <Typography style={{ justifySelf: "center" }}>
          {i18n.t("noteDrawer.header")} #{ticket?.id}
        </Typography>
      </div>
      {loading ? (
        <ContactDrawerSkeleton classes={classes} />
      ) : (
        <div className={classes.content}>
          {notes &&
            notes.length > 0 &&
            notes.map((note, index) => (
              <Accordion key={note.id}>
                <AccordionSummary
                  key={index}
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls={`panel${index}a-header`}
                  id={`panel${index}a-header`}
                >
                  <Typography variant="body1" style={{ marginRight: "1rem" }}>
                    {index + 1}. {note?.title || i18n.t("noteDrawer.noTitle")}
                  </Typography>

                  <IconButton
                    aria-label="edit"
                    className={classes.iconButtonAccordion}
                    onClick={() => handleUpdateNote(note)}
                  >
                    <EditIcon />
                  </IconButton>
                  <IconButton
                    aria-label="delete"
                    className={classes.iconButtonAccordion}
                    onClick={() => handleConfirmDelete(note)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </AccordionSummary>
                <AccordionDetails>
                  <Paper
                    variant="outlined"
                    style={{
                      padding: "1rem",
                      backgroundColor: "#fff",
                      boxShadow: "none",
                      border: "1px solid rgba(0, 0, 0, 0.12)",
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    {note && note.body ? (
                      parse(note.body)
                    ) : (
                      <Typography
                        style={{ whiteSpace: "pre-wrap" }}
                        variant="body1"
                      >
                        <MarkdownWrapper>
                          {i18n.t("noteDrawer.noBody")}
                        </MarkdownWrapper>
                      </Typography>
                    )}
                    <Divider
                      style={{ marginTop: "1.5rem", marginBottom: "0.5rem" }}
                    />
                    <Typography variant="body2">
                      {i18n.t("noteDrawer.files")}&nbsp; (
                      {note?.files?.length || 0})
                    </Typography>
                    <List dense>
                      {note?.files?.map((file, index) => (
                        <ListItem style={{ paddingLeft: 0 }} key={index}>
                          {file.mediaUrl ? (
                            <ListItemAvatar>
                              <Avatar
                                variant="square"
                                src={file.mediaUrl}
                                style={{ width: 30, height: 30 }}
                              />
                            </ListItemAvatar>
                          ) : null}
                          <ListItemText
                            primary={`# ${index + 1} - ${
                              file.mediaType || file.type
                            }`}
                            secondary={
                              file.mediaOriginalName ||
                              i18n.t("noteDrawer.fileName") + index
                            }
                          />
                          <ListItemSecondaryAction>
                            {file.mediaUrl ? (
                              <IconButton
                                edge="end"
                                aria-label="view"
                                href={file.mediaUrl || file.url}
                                target="_blank"
                              >
                                <Visibility />
                              </IconButton>
                            ) : null}
                          </ListItemSecondaryAction>
                        </ListItem>
                      ))}
                    </List>
                    <Divider
                      style={{ marginTop: "1.5rem", marginBottom: "0.5rem" }}
                    />
                    <Typography variant="body2">
                      {i18n.t("noteDrawer.createdAt")}&nbsp;
                      {note?.createdAt
                        ? format(parseISO(note.createdAt), "dd MMM yyyy HH:mm")
                        : null}
                    </Typography>
                    <Typography variant="body2">
                      {i18n.t("noteDrawer.createdBy")}&nbsp;
                      {note?.user?.name}
                    </Typography>
                  </Paper>
                </AccordionDetails>
              </Accordion>
            ))}
          <Button
            variant="contained"
            color="primary"
            style={{ marginTop: 10, marginBottom: 10 }}
            onClick={handleOpenModal}
          >
            {i18n.t("noteDrawer.addNote")}
          </Button>
          <NoteModal
            open={modalOpen}
            onClose={handleCloseModal}
            note={note}
            ticketId={ticket.id}
            contactId={ticket.contact.id}
          />
        </div>
      )}
    </Drawer>
  );
};

export default NoteDrawer;
