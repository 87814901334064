import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  FormControlLabel,
  FormGroup,
  Paper,
  Radio,
  RadioGroup,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";

import { makeStyles } from "@material-ui/core/styles";
import { i18n } from "../../translate/i18n";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import opacityColor from "../../utils/opacity-color";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },

  body: {
    padding: "1.5rem",
  },
}));

const ContactFilterModal = ({
  open,
  handleClose,
  updateFilters,
  currentFilters,
  labelList
}) => {
  const classes = useStyles();
  const [selectedLabelIds, setSelectedLabelIds] = useState([]);
  const [contactType, setContactType] = useState(
    currentFilters.contactType || "all"
  );

  useEffect(() => {
    setSelectedLabelIds(currentFilters.labels);
  }, [currentFilters]);

  const handleChange = (event) => {
    const { value } = event.target;

    selectedLabelIds.includes(value)
      ? setSelectedLabelIds(
          selectedLabelIds.filter((labelId) => labelId !== value)
        )
      : setSelectedLabelIds([...selectedLabelIds, value]);
  };

  const handleClearAll = () => {
    setSelectedLabelIds([]);
    setContactType("all");

    updateFilters({ labels: [], contactType: "all", sort: currentFilters.sort });
    handleClose();
  };

  const handleApply = () => {
    updateFilters({ labels: selectedLabelIds, contactType, sort: currentFilters.sort });
    handleClose();
  };

  return (
    <div className={classes.root}>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="sm"
        fullWidth
        scroll="paper"
      >
        <DialogTitle>{i18n.t("contactFilterModal.title")}</DialogTitle>
        <DialogContent dividers style={{ gap: "1rem", display: "flex", flexDirection: "column" }}>
          <Paper variant="outlined" className={classes.body}>
            <Typography variant="h6">
              {i18n.t("contactFilterModal.labelFilter")}
            </Typography>
            <FormGroup
              row
              style={{
                gap: "1rem",
                paddingLeft: "0.5rem",
                marginTop: "0.5rem",
              }}
            >
              {labelList && labelList.length > 0
                ? labelList.map((label) => (
                    <FormControlLabel
                      key={label.labelSlug}
                      control={
                        <Checkbox
                          key={label.labelSlug}
                          value={label.labelSlug}
                          checked={selectedLabelIds.includes(label.labelSlug)}
                          style={{
                            backgroundColor: opacityColor(label.color, 10),
                            marginRight: 5,
                            color: label.color,
                            borderColor: label.color,
                          }}
                          onChange={handleChange}
                          name={label.name}
                        />
                      }
                      label={label.name}
                    />
                  ))
                : null}
            </FormGroup>
          </Paper>

          <Paper variant="outlined" className={classes.body}>
            <Typography variant="h6">
              {i18n.t("contactFilterModal.showGroupContacts")}
            </Typography>
            <RadioGroup
              style={{
                gap: "0.5rem",
                paddingLeft: "0.5rem",
                marginTop: "0.5rem",
              }}
              value={contactType}
              onChange={(e) => setContactType(e.target.value)}
            >
              <FormControlLabel
                value="all"
                control={<Radio />}
                label={i18n.t("contactFilterModal.showAll")}
              />
              <FormControlLabel
                value="contacts"
                control={<Radio />}
                label={i18n.t("contactFilterModal.showContactOnly")}
              />
              <FormControlLabel
                value="groups"
                control={<Radio />}
                label={i18n.t("contactFilterModal.showGroupOnly")}
              />
            </RadioGroup>
          </Paper>
        </DialogContent>
        <DialogActions style={{ justifyContent: "space-between" }}>
          <div>
            <Button color="primary" onClick={handleClearAll}>
              {i18n.t("contactFilterModal.buttons.clear")}
            </Button>
          </div>
          <div style={{ display: "flex", gap: "0.5rem" }}>
            <Button color="secondary" variant="outlined" onClick={handleClose}>
              {i18n.t("contactFilterModal.buttons.cancel")}
            </Button>
            <Button
              type="submit"
              color="primary"
              variant="contained"
              onClick={handleApply}
            >
              {i18n.t("contactFilterModal.buttons.apply")}
            </Button>
          </div>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ContactFilterModal;
