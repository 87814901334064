import React, { useState, useEffect } from "react";
import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import { toast } from "react-toastify";

import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";

import {
  Dialog,
  DialogContent,
  DialogTitle,
  Button,
  DialogActions,
  CircularProgress,
  TextField,
  Switch,
  FormControlLabel,
} from "@material-ui/core";

import api from "../../services/api";
import { i18n } from "../../translate/i18n";
import toastError from "../../errors/toastError";
import QueueSelect from "../QueueSelect";
import FarewellMessageTemplateSelect from "../FarewellMessageTemplateSelect";
import { getChatBotStatus } from "../../config";
import TypeBotSelect from "../TypeBotSelect";
import TimeAutoCloseTicket from "../TimeAutoCloseSelect";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },

  multFieldLine: {
    display: "flex",
    "& > *:not(:last-child)": {
      marginRight: theme.spacing(1),
    },
  },

  btnWrapper: {
    position: "relative",
  },

  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
}));

const SessionSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Required"),
  token: Yup.string().required("Required"),
});

const WhatsAppOfficialModal = ({ open, onClose, whatsAppOfficialId }) => {
  const classes = useStyles();
  const initialState = {
    name: "",
    token: "",
    greetingMessage: "",
    farewellMessage: "",
    farewellTemplateName: "",
    isDefault: false,
  };
  const [whatsAppOfficial, setWhatsAppOfficial] = useState(initialState);
  const [selectedQueueIds, setSelectedQueueIds] = useState([]);
  const [selectedTemplateName, setSelectedTemplateName] = useState("");
  const [selectedTimeAutoCloseTicket, setSelectedTimeAutoCloseTicket] = useState(0);
  const [selectedTypeBot, setSelectedTypeBot] = useState("");

  useEffect(() => {
    const fetchSession = async () => {
      if (!whatsAppOfficialId) {
        // reset form
        setWhatsAppOfficial(initialState);
        setSelectedQueueIds([]);
        setSelectedTemplateName("");
        setSelectedTypeBot("");
        return;
      };

      try {
        const { data } = await api.get(`whatsapp/${whatsAppOfficialId}`);
        setWhatsAppOfficial(data);

        const whatsQueueIds = data.queues?.map((queue) => queue.id);
        setSelectedQueueIds(whatsQueueIds || []);

        setSelectedTimeAutoCloseTicket(data.autoCloseTicket || 0);

        setSelectedTemplateName(data.farewellTemplateName || "");
        setSelectedTypeBot(data.typeBotId || "");
      } catch (err) {
        toastError(err);
      }
    };
    fetchSession();
  }, [whatsAppOfficialId]);

  const handleSaveWhatsAppOfficial = async (values) => {
    const whatsappOfficialData = {
      ...values,
      queueIds: selectedQueueIds,
      farewellTemplateName: selectedTemplateName,
      channel: "whatsappOfficial",
      autoCloseTicket: selectedTimeAutoCloseTicket,
      typeBotId: selectedTypeBot,
    };

    try {
      if (whatsAppOfficialId) {
        await api.put(`/whatsapp/${whatsAppOfficialId}`, whatsappOfficialData);
      } else {
        await api.post("/whatsapp", whatsappOfficialData);
      }
      toast.success(i18n.t("whatsAppOfficialModal.success"));
      handleClose();
    } catch (err) {
      toastError(err);
    }
  };

  const handleClose = () => {
    onClose();
    setWhatsAppOfficial(initialState);
  };

  return (
    <div className={classes.root}>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="sm"
        fullWidth
        scroll="paper"
      >
        <DialogTitle>
          {whatsAppOfficialId
            ? i18n.t("whatsAppOfficialModal.title.edit")
            : i18n.t("whatsAppOfficialModal.title.add")}
        </DialogTitle>
        <Formik
          initialValues={whatsAppOfficial}
          enableReinitialize={true}
          validationSchema={SessionSchema}
          onSubmit={(values, actions) => {
            setTimeout(() => {
              handleSaveWhatsAppOfficial(values);
              actions.setSubmitting(false);
            }, 400);
          }}
        >
          {({ values, touched, errors, isSubmitting }) => (
            <Form>
              <DialogContent dividers>
                <div className={classes.multFieldLine}>
                  <Field
                    as={TextField}
                    label={i18n.t("whatsAppOfficialModal.form.name")}
                    autoFocus
                    name="name"
                    error={touched.name && Boolean(errors.name)}
                    helperText={touched.name && errors.name}
                    variant="outlined"
                    margin="dense"
                    className={classes.textField}
                  />
                  <FormControlLabel
                    control={
                      <Field
                        as={Switch}
                        color="primary"
                        name="isDefault"
                        checked={values.isDefault}
                      />
                    }
                    label={i18n.t("whatsAppOfficialModal.form.default")}
                  />
                  
                  {getChatBotStatus() ? (
                    <FormControlLabel
                      control={
                        <Field
                          as={Switch}
                          color="primary"
                          name="isBot"
                          checked={values.isBot || false}
                        />
                      }
                      label={i18n.t("whatsAppOfficialModal.form.isBot")}
                    />
                  ) : null}
                </div>
                <div>
                  <Field
                    as={TextField}
                    label={i18n.t("whatsAppOfficialModal.form.token")}
                    name="token"
                    error={touched.token && Boolean(errors.token)}
                    helperText={touched.token && errors.token}
                    variant="outlined"
                    margin="dense"
                    className={classes.textField}
                    fullWidth
                  />
                </div>
                <div>
                  <Field
                    as={TextField}
                    label={i18n.t("queueModal.form.greetingMessage")}
                    type="greetingMessage"
                    multiline
                    minRows={5}
                    fullWidth
                    name="greetingMessage"
                    error={
                      touched.greetingMessage && Boolean(errors.greetingMessage)
                    }
                    helperText={
                      touched.greetingMessage && errors.greetingMessage
                    }
                    variant="outlined"
                    margin="dense"
                  />
                </div>
                <div style={{ display: "none" }}>
                  <Field
                    as={TextField}
                    label={i18n.t("whatsAppOfficialModal.form.farewellMessage")}
                    type="farewellMessage"
                    multiline
                    minRows={5}
                    fullWidth
                    name="farewellMessage"
                    error={
                      touched.farewellMessage && Boolean(errors.farewellMessage)
                    }
                    helperText={
                      touched.farewellMessage && errors.farewellMessage
                    }
                    variant="outlined"
                    margin="dense"
                  />
                </div>
                {values.token ? (
                  <div style={{ marginTop: "6px" }}>
                    <FarewellMessageTemplateSelect
                      selectedTemplateName={selectedTemplateName}
                      onChange={(selectedName) =>
                        setSelectedTemplateName(selectedName)
                      }
                    />
                  </div>
                ) : null}
                {values.isBot && (
                  <TypeBotSelect
                    selectedTypeBotId={selectedTypeBot}
                    onChange={(selectedBot) => setSelectedTypeBot(selectedBot)}
                  />
                )}
                <QueueSelect
                  selectedQueueIds={selectedQueueIds}
                  onChange={(selectedIds) => setSelectedQueueIds(selectedIds)}
                />
                <TimeAutoCloseTicket selectedTimeAutoCloseTicket={selectedTimeAutoCloseTicket} onChange={(selectedIds) => setSelectedTimeAutoCloseTicket(selectedIds)} />
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={handleClose}
                  color="secondary"
                  disabled={isSubmitting}
                  variant="outlined"
                >
                  {i18n.t("whatsAppOfficialModal.buttons.cancel")}
                </Button>
                <Button
                  type="submit"
                  color="primary"
                  disabled={isSubmitting}
                  variant="contained"
                  className={classes.btnWrapper}
                >
                  {whatsAppOfficialId
                    ? i18n.t("whatsAppOfficialModal.buttons.okEdit")
                    : i18n.t("whatsAppOfficialModal.buttons.okAdd")}
                  {isSubmitting && (
                    <CircularProgress
                      size={24}
                      className={classes.buttonProgress}
                    />
                  )}
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
    </div>
  );
};

export default React.memo(WhatsAppOfficialModal);
