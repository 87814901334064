import { FormControl, InputLabel, Select } from "@material-ui/core";
import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { i18n } from "../../translate/i18n";

const useStyles = makeStyles((theme) => ({}));

const TimeAutoCloseTicket = ({ selectedTimeAutoCloseTicket, onChange }) => {
  const classes = useStyles();

  const handleChange = (e) => {
    onChange(e.target.value);
  };

  return (
    <div style={{ marginTop: 6 }}>
      <FormControl fullWidth margin="dense" variant="outlined">
        <InputLabel>{i18n.t("timeAutoCloseSelect.inputLabel")}</InputLabel>
        <Select
          labelWidth={130}
          margin="dense"
          variant="outlined"
          native
          id="timeAutoCloseTicket-setting"
          name="timeAutoCloseTicket"
          value={selectedTimeAutoCloseTicket}
          className={classes.settingOption}
          onChange={handleChange}
          MenuProps={{
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left",
            },
            transformOrigin: {
              vertical: "top",
              horizontal: "left",
            },
            getContentAnchorEl: null,
          }}
        >
          <option value="0">{i18n.t("timeAutoCloseSelect.options.0")}</option>
          <option value="8">{i18n.t("timeAutoCloseSelect.options.8")}</option>
          <option value="16">{i18n.t("timeAutoCloseSelect.options.16")}</option>
          <option value="24">{i18n.t("timeAutoCloseSelect.options.24")}</option>
          <option value="32">{i18n.t("timeAutoCloseSelect.options.32")}</option>
          <option value="40">{i18n.t("timeAutoCloseSelect.options.40")}</option>
          <option value="48">{i18n.t("timeAutoCloseSelect.options.48")}</option>
          <option value="72">{i18n.t("timeAutoCloseSelect.options.72")}</option>
          <option value="168">
            {i18n.t("timeAutoCloseSelect.options.168")}
          </option>
          <option value="336">
            {i18n.t("timeAutoCloseSelect.options.336")}
          </option>
          <option value="720">
            {i18n.t("timeAutoCloseSelect.options.720")}
          </option>
          <option value="4380">
            {i18n.t("timeAutoCloseSelect.options.4380")}
          </option>
          <option value="8760">
            {i18n.t("timeAutoCloseSelect.options.8760")}
          </option>
          <option value="9999">
            {i18n.t("timeAutoCloseSelect.options.9999")}
          </option>
        </Select>
      </FormControl>
    </div>
  );
};

export default TimeAutoCloseTicket;
