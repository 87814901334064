import React, { useState, useEffect, useRef } from "react";

import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import { toast } from "react-toastify";

import {
  makeStyles,
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  CircularProgress,
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Typography,
  Card,
  CardContent,
  Backdrop,
} from "@material-ui/core";
import { green } from "@material-ui/core/colors";
import { i18n } from "../../translate/i18n";

import api from "../../services/api";
import toastError from "../../errors/toastError";
import { DeleteOutline, Visibility } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  root: {
    flexWrap: "wrap",
  },
  textField: {
    marginRight: theme.spacing(1),
    width: "100%",
  },

  btnWrapper: {
    position: "relative",
  },

  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  textQuickAnswerContainer: {
    width: "100%",
  },
}));

const QuickAnswerSchema = Yup.object().shape({
  shortcut: Yup.string()
    .min(2, "Too Short!")
    .max(15, "Too Long!")
    .required("Required"),
  message: Yup.string()
    .min(8, "Too Short!")
    .max(30000, "Too Long!")
    .required("Required"),
});

const QuickAnswersModal = ({
  open,
  onClose,
  quickAnswerId,
  initialValues,
  onSave,
}) => {
  const classes = useStyles();
  const isMounted = useRef(true);

  const initialState = {
    shortcut: "",
    message: "",
    files: [],
  };

  const [quickAnswer, setQuickAnswer] = useState(initialState);
  const [isUploading, setIsUploading] = useState(false);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  const handleClose = () => {
    onClose();
    setQuickAnswer(initialState);
  };

  const handleDeleteImage = async (index) => {
    try {
      if (quickAnswerId) {
        await api.delete(
          `/quickAnswers/${quickAnswerId}/files/${quickAnswer.files[index].id}`
        );
        quickAnswer.files.splice(index, 1);
        setQuickAnswer({ ...quickAnswer, files: [...quickAnswer.files] });

        toast.success(i18n.t("quickAnswersModal.successDeleteImage"));
      } else {
        quickAnswer.files.splice(index, 1);
        setQuickAnswer({ ...quickAnswer, files: [...quickAnswer.files] });
      }
    } catch (err) {
      console.log(err);
      toastError(err);
    }
  };

  const handleSaveQuickAnswer = async (values) => {
    try {
      if (quickAnswerId) {
        await api.put(`/quickAnswers/${quickAnswerId}`, values);
        handleClose();
      } else {
        const formData = new FormData();

        formData.append("shortcut", values.shortcut);
        formData.append("message", values.message);

        if (quickAnswer.files) {
          quickAnswer.files.forEach((file) => {
            formData.append("files", file);
          });
        }

        const { data } = await api.post("/quickAnswers", formData);

        if (onSave) {
          onSave(data);
        }
        handleClose();
      }
      toast.success(i18n.t("quickAnswersModal.success"));
    } catch (err) {
      toastError(err);
    }
  };

  const handleUploadfiles = async (event) => {
    if (quickAnswerId) {
      setIsUploading(true);
      const uploadedFiles = Array.from(event.target.files);
      const formData = new FormData();

      uploadedFiles.forEach((file) => formData.append("files", file));

      try {
        await api.post(`/quickAnswers/${quickAnswerId}/files`, formData);

        const { data } = await api.get(`/quickAnswers/${quickAnswerId}`);
        setQuickAnswer(data);

        toast.success(i18n.t("quickAnswersModal.successAddImage"));
      } catch (error) {
        toastError(error);
      } finally {
        setIsUploading(false);
      }
    } else {
      const uploadedFiles = Array.from(event.target.files);

      uploadedFiles.forEach((file) => {
        quickAnswer.files.push(file);
      });
      setQuickAnswer({ ...quickAnswer, files: [...quickAnswer.files] });
    }
  };

  useEffect(() => {
    const fetchQuickAnswer = async () => {
      if (initialValues) {
        setQuickAnswer((prevState) => {
          return { ...prevState, ...initialValues };
        });
      }

      if (!quickAnswerId) return;

      try {
        const { data } = await api.get(`/quickAnswers/${quickAnswerId}`);
        if (isMounted.current) {
          setQuickAnswer(data);
        }
      } catch (err) {
        toastError(err);
      }
    };

    fetchQuickAnswer();
  }, [quickAnswerId, open, initialValues]);

  return (
    <div className={classes.root}>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="sm"
        fullWidth
        scroll="paper"
      >
        <DialogTitle id="form-dialog-title">
          {quickAnswerId
            ? `${i18n.t("quickAnswersModal.title.edit")}`
            : `${i18n.t("quickAnswersModal.title.add")}`}
        </DialogTitle>
        <Formik
          initialValues={quickAnswer}
          enableReinitialize={true}
          validationSchema={QuickAnswerSchema}
          onSubmit={(values, actions) => {
            setTimeout(() => {
              handleSaveQuickAnswer(values);
              actions.setSubmitting(false);
            }, 400);
          }}
        >
          {({ values, errors, touched, isSubmitting }) => (
            <Form>
              <DialogContent dividers>
                <div className={classes.textQuickAnswerContainer}>
                  <Field
                    as={TextField}
                    label={i18n.t("quickAnswersModal.form.shortcut")}
                    name="shortcut"
                    autoFocus
                    error={touched.shortcut && Boolean(errors.shortcut)}
                    helperText={touched.shortcut && errors.shortcut}
                    variant="outlined"
                    margin="dense"
                    className={classes.textField}
                    fullWidth
                  />
                </div>
                <div className={classes.textQuickAnswerContainer}>
                  <Field
                    as={TextField}
                    label={i18n.t("quickAnswersModal.form.message")}
                    name="message"
                    error={touched.message && Boolean(errors.message)}
                    helperText={touched.message && errors.message}
                    variant="outlined"
                    margin="dense"
                    className={classes.textField}
                    multiline
                    rows={5}
                    fullWidth
                  />
                </div>
                <div
                  className={classes.textQuickAnswerContainer}
                  style={{ marginTop: 5 }}
                >
                  <Card variant="outlined" style={{ borderColor: "#C4C4C4" }}>
                    <CardContent>
                      <Typography variant="subtitle1">
                        {i18n.t("quickAnswersModal.form.files")}
                      </Typography>
                      {quickAnswer.files && quickAnswer.files.length > 0 ? (
                        <List dense>
                          {quickAnswer.files.map((image, index) => (
                            <ListItem style={{ paddingLeft: 0 }}>
                              {image.mediaUrl ? (
                                <ListItemAvatar>
                                  <Avatar
                                    variant="square"
                                    src={image.mediaUrl}
                                    style={{ width: 50, height: 50 }}
                                  />
                                </ListItemAvatar>
                              ) : null}
                              <ListItemText
                                primary={`# ${index + 1} - ${
                                  image.mediaType || image.type
                                }`}
                                secondary={
                                  image.mediaOriginalName || image.name
                                }
                              />
                              <ListItemSecondaryAction>
                                {image.mediaUrl ? (
                                  <IconButton
                                    edge="end"
                                    aria-label="view"
                                    href={image.mediaUrl || image.url}
                                    target="_blank"
                                  >
                                    <Visibility />
                                  </IconButton>
                                ) : null}
                                <IconButton edge="end" aria-label="delete">
                                  <DeleteOutline
                                    style={{ color: "red" }}
                                    onClick={() => handleDeleteImage(index)}
                                  />
                                </IconButton>
                              </ListItemSecondaryAction>
                            </ListItem>
                          ))}
                        </List>
                      ) : (
                        <Typography variant="subtitle2">
                          {i18n.t("quickAnswersModal.form.noFiles")}
                        </Typography>
                      )}
                      <Button
                        variant="outlined"
                        color="primary"
                        component="label"
                        disabled={isSubmitting || isUploading}
                        style={{ marginTop: 5 }}
                      >
                        {i18n.t("quickAnswersModal.form.selectFiles")}
                        <input
                          type="file"
                          hidden
                          name="files"
                          id="files"
                          multiple
                          onChange={(e) => {
                            handleUploadfiles(e);
                          }}
                        />
                      </Button>
                    </CardContent>
                  </Card>
                </div>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={handleClose}
                  color="secondary"
                  disabled={isSubmitting || isUploading}
                  variant="outlined"
                >
                  {i18n.t("quickAnswersModal.buttons.cancel")}
                </Button>
                <Button
                  type="submit"
                  color="primary"
                  disabled={isSubmitting || isUploading}
                  variant="contained"
                  className={classes.btnWrapper}
                >
                  {quickAnswerId
                    ? `${i18n.t("quickAnswersModal.buttons.okEdit")}`
                    : `${i18n.t("quickAnswersModal.buttons.okAdd")}`}
                  {isSubmitting && (
                    <CircularProgress
                      size={24}
                      className={classes.buttonProgress}
                    />
                  )}
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
    </div>
  );
};

export default QuickAnswersModal;
