import React, { useEffect } from "react";
import toastError from "../../errors/toastError";

import Typography from "@material-ui/core/Typography";
// import Grid from "@material-ui/core/Grid";

import { Button, Divider } from "@material-ui/core";

const LocationPreview = ({ image, link, description }) => {
  useEffect(() => {}, [image, link, description]);

  const handleLocation = async () => {
    try {
      window.open(link);
    } catch (err) {
      toastError(err);
    }
  };

  return (
    <>
      <div
        style={{
          minWidth: "250px",
        }}
      >
        <div>
          <div style={{ float: "left" }}>
            {image ? (
              <img
                src={
                  image ||
                  "data:image/jpeg;base64,/9j/4AAQSkZJRgABAQEAYABgAAD//gA7Q1JFQVRPUjogZ2QtanBlZyB2MS4wICh1c2luZyBJSkcgSlBFRyB2ODApLCBxdWFsaXR5ID0gOTAK/9sAQwADAgIDAgIDAwMDBAMDBAUIBQUEBAUKBwcGCAwKDAwLCgsLDQ4SEA0OEQ4LCxAWEBETFBUVFQwPFxgWFBgSFBUU/9sAQwEDBAQFBAUJBQUJFA0LDRQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQU/8AAEQgAZABkAwERAAIRAQMRAf/EAB8AAAEFAQEBAQEBAAAAAAAAAAABAgMEBQYHCAkKC//EALUQAAIBAwMCBAMFBQQEAAABfQECAwAEEQUSITFBBhNRYQcicRQygZGhCCNCscEVUtHwJDNicoIJChYXGBkaJSYnKCkqNDU2Nzg5OkNERUZHSElKU1RVVldYWVpjZGVmZ2hpanN0dXZ3eHl6g4SFhoeIiYqSk5SVlpeYmZqio6Slpqeoqaqys7S1tre4ubrCw8TFxsfIycrS09TV1tfY2drh4uPk5ebn6Onq8fLz9PX29/j5+v/EAB8BAAMBAQEBAQEBAQEAAAAAAAABAgMEBQYHCAkKC//EALURAAIBAgQEAwQHBQQEAAECdwABAgMRBAUhMQYSQVEHYXETIjKBCBRCkaGxwQkjM1LwFWJy0QoWJDThJfEXGBkaJicoKSo1Njc4OTpDREVGR0hJSlNUVVZXWFlaY2RlZmdoaWpzdHV2d3h5eoKDhIWGh4iJipKTlJWWl5iZmqKjpKWmp6ipqrKztLW2t7i5usLDxMXGx8jJytLT1NXW19jZ2uLj5OXm5+jp6vLz9PX29/j5+v/aAAwDAQACEQMRAD8A/VOgAoAKACgAoAKACgAoAKACgAoAKACgAoAKACgAoAKACgAoAKACgAoAKACgAoAKACgAoAKACgAoAKACgAoAKACgAoAKACgAoAKACgAoAKACgAoAKACgAoAKACgAoAKACgAoAKAM+71mG3mMEayXNwOTFAu4qPc9F/EigCP+2pY+ZtNu4Yx1cBJMfgjE/pQK5ftbqG9hWaCRZY26MpyKBktABQAUAFABQAUAFABQAUAUNZupLa1VISBcTOsUZIyAT3x3wMn8KAMhL+18GTR2t7cotrcMzRXErAPu6sH9f978D2yE7Fh/HOgJt/4mts247flkBx9fagd0S3Krpl/DeQ/LBcyCOdR0JPCP9c4B9QfYUDNmgAoAKACgAoAKACgAoAKAMvWz5Umnzn7kVyu4+m5WQH82FAHIfFbw7qOvHSzYWjXYhMm8KQMZ246n2NNESVzjNX8N65BDsFjdpFI4RXlZV8tW48vIY/KeOuBwOOaZPKeu6uM6fZW//LWSaFQv+6wY/kFJ/CpNDYHAFAxaACgAoAKACgAoAKACgCK5t47u3khlUPHIpVlPcGgDLiubzSB5VxDLewLwlzENz4/216k+65z6CgQ6bWbe7ieFbK6ut4KmI2zKGB7EuAP1oC4zRNHntmWe8lMkiArBEW3CBCem7+I9Bn0GPUkA2qBhQAUAFABQAUAFABQAUAFACEZoAMUALQAUAFABQAUAFABQAUAFABQAUAFABQAUAFABQAUAFABQAUAFABQAUAFABQAUAFABQAUAFABQAUAFABQAUAFABQAUAFABQAUAFABQAUAFABQAUAFABQAUAFABQAUAFABQAUAFABQB/9k="
                }
                onClick={handleLocation}
                style={{ width: "100px" }}
              />
            ) : null}
          </div>
          {description && (
            <div style={{ display: "flex", flexWrap: "wrap" }}>
              <Typography
                style={{
                  marginTop: "12px",
                  marginLeft: "15px",
                  marginRight: "15px",
                  float: "left",
                }}
                variant="subtitle1"
                color="primary"
                gutterBottom
              >
                <div
                  dangerouslySetInnerHTML={{
                    __html: description.replace("\\n", "<br />"),
                  }}
                ></div>
              </Typography>
            </div>
          )}
          <div style={{ display: "block", content: "", clear: "both" }}></div>
          <div>
            <Divider />
            <Button
              fullWidth
              color="primary"
              onClick={handleLocation}
              disabled={!link}
            >
              Click to View
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default LocationPreview;
