import React, { useState } from "react";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { i18n } from "../../translate/i18n";

const TypeIntegrationSelect = ({ selectedITypeIntegrationId, onChange }) => {
  const [typeIntegration] = useState([
    { id: 1, name: "Waapy Bot", value: "typebot" },
  ]);

  const handleChange = (e) => {
    onChange(e.target.value);
  };

  return (
    <div style={{ marginTop: 6 }}>
      <FormControl fullWidth margin="dense" variant="outlined">
        <InputLabel>{i18n.t("typeIntegrationSelect.inputLabel")}</InputLabel>
        <Select
          labelWidth={40}
          value={selectedITypeIntegrationId}
          onChange={handleChange}
          MenuProps={{
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left",
            },
            transformOrigin: {
              vertical: "top",
              horizontal: "left",
            },
            getContentAnchorEl: null,
          }}
        >
          {typeIntegration.map((chatFlow) => (
            <MenuItem key={chatFlow.id} value={chatFlow.value}>
              {chatFlow.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};

export default TypeIntegrationSelect;
