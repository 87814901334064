import {
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  FormControlLabel,
  FormGroup,
  Link,
  Paper,
  Radio,
  RadioGroup,
} from "@material-ui/core";
import openSocket from "../../services/socket-io";
import Divider from "@material-ui/core/Divider";
import React, { useEffect, useState } from "react";
import { i18n } from "../../translate/i18n";
import Button from "@material-ui/core/Button";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import { makeStyles } from "@material-ui/core/styles";
import api from "../../services/api";
import toastError from "../../errors/toastError";
import { useHistory } from "react-router-dom";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import PropTypes from "prop-types";
import { CloudDownload, SyncRounded } from "@material-ui/icons";
import opacityColor from "../../utils/opacity-color";
import { format } from "date-fns";
import ConnectionSelect from "../ConnectionSelect";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  mainPaper: {
    overflowY: "scroll",
    ...theme.scrollbarStyles,
    padding: 0,
    margin: 0,
  },

  mainHeaderSub: {
    flex: "none",
    backgroundColor: "#eee",
  },

  body: {
    padding: "1.5rem",
  },
}));

const ContactImportExportModal = ({ open, onClose, labelList }) => {
  const classes = useStyles();
  const history = useHistory();

  const [loadingImportFile, setLoadingImportFile] = useState(false);
  const [importResults, setImportResults] = useState([]);
  const [value, setValue] = useState(0);
  const [selectedLabelIds, setSelectedLabelIds] = useState([]);
  const [contactType, setContactType] = useState("all");
  const [connectionId, setConnectionId] = useState("");

  const [loadingExportFile, setLoadingExportFile] = useState(false);
  const [loadingSyncContact, setLoadingSyncContact] = useState(false);

  useEffect(() => {
    const socket = openSocket();

    socket.on("contact", (data) => {
      if (
        data.action === "import-file-success" ||
        data.action === "import-file-error"
      ) {
        const details = data.message;

        // push the details object to the importResults array
        const detailsArray = [details];

        setImportResults((prev) => [...prev, ...detailsArray]);
      }
    });

    return () => {
      socket.disconnect();
    };
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleLabelChange = (event) => {
    const { value } = event.target;

    selectedLabelIds.includes(value)
      ? setSelectedLabelIds(
          selectedLabelIds.filter((labelId) => labelId !== value)
        )
      : setSelectedLabelIds([...selectedLabelIds, value]);
  };

  const handleFileUpload = async (event) => {
    setLoadingImportFile(true);

    const formData = new FormData();
    formData.append("medias", event);

    try {
      await api.post("/contacts/import/file", formData);
    } catch (err) {
      toastError(err);
    }

    setLoadingImportFile(false);
  };

  const handleImportFileDialogClose = () => {
    if (importResults.length > 0) {
      setImportResults([]);

      history.go(0);
    }

    onClose();
  };

  const handleExportFile = async () => {
    setLoadingExportFile(true);

    try {
      const response = await api.get("/contacts/export/file", {
        params: {
          labels: selectedLabelIds,
          type: contactType
        }
      });
      const blob = new Blob([response.data], { type: "text/csv" });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${contactType + '-' + format(new Date(), 'dd-MM-yyyy HH:mm:ss')}.csv`);
      document.body.appendChild(link);
      link.click();
    } catch (err) {
      toastError(err);
    }

    setLoadingExportFile(false);
  };

  const handleClose = () => {
    onClose();
  };

  const handleConnectionChange = (connectionId) => {
    setConnectionId(connectionId);
  }

  const handleSyncContact = async () => {
      setLoadingSyncContact(true);

      try {
        await api.post("/contacts/import", { connectionId: connectionId });
        history.go(0);
      } catch (err) {
        toastError(err);
      }

      setLoadingSyncContact(false);
  }
  return (
    <Dialog open={open} fullWidth maxWidth="md" onClose={handleClose}>
      <DialogContent style={{ padding: 0 }}>
        <Paper square className={classes.mainHeaderSub}>
          <Tabs
            value={value}
            indicatorColor="primary"
            textColor="primary"
            variant="fullWidth"
            onChange={handleChange}
            centered
            aria-label="icon label tabs example"
          >
            <Tab
              icon={<CloudUploadIcon />}
              label={i18n.t("contacts.importExportFile.importByFileTab")}
              {...a11yProps(0)}
            />
            <Tab
              icon={<SyncRounded />}
              label={i18n.t("contacts.importExportFile.syncContactTab")}
              {...a11yProps(1)}
            />
            <Tab
              icon={<CloudDownload />}
              label={i18n.t("contacts.importExportFile.exportContactTab")}
              {...a11yProps(2)}
            />
          </Tabs>
        </Paper>
        <Paper square>
          <TabPanel value={value} index={0}>
            <p>{i18n.t("contacts.importExportFile.message")}</p>
            <p>
              <Link href="/static/contacts.xlsx" download="contacts.xlsx">
                {i18n.t("contacts.importExportFile.exampleFile")}
              </Link>
            </p>
            <Button
              variant="contained"
              color="primary"
              className={classes.button}
              startIcon={<CloudUploadIcon />}
              component="label"
              disabled={loadingImportFile}
            >
              {loadingImportFile
                ? i18n.t("contacts.importExportFile.buttonLoading")
                : i18n.t("contacts.importExportFile.button")}
              <input
                type="file"
                accept=".xlsx, .xls"
                name="medias"
                hidden
                onChange={(event) => handleFileUpload(event.target.files[0])}
              />
            </Button>
            {importResults.length > 0 && (
              <>
                <Divider style={{ margin: "2rem 0" }} />
                <Paper
                  style={{
                    padding: "1rem",
                    border: "1px solid #ccc",
                    borderRadius: "5px",
                    overflow: "auto",
                    height: "300px",
                    fontFamily: "monospace",
                  }}
                >
                  <ol>
                    {importResults.map((details, index) => (
                      <li key={index}>
                        <b>{details.number}</b>
                        <br />
                        {i18n.exists(`backendErrors.${details.reason}`)
                          ? i18n.t(`backendErrors.${details.reason}`)
                          : details.reason}
                      </li>
                    ))}
                  </ol>
                  <p>
                    {loadingImportFile
                      ? i18n.t("contacts.importExportFile.buttonLoading")
                      : i18n.t("contacts.importExportFile.finish")}
                  </p>
                </Paper>
              </>
            )}
          </TabPanel>
          <TabPanel value={value} index={1}>
            <p>{i18n.t("contacts.importExportFile.syncContact.message")}</p>

            <Box
              display={"flex"}
              flexDirection={"column"}
              style={{ gap: "1rem" }}
            >
              <Paper variant="outlined" className={classes.body}>
                <ConnectionSelect onChange={handleConnectionChange} channel={"whatsappUnofficial"} />

                <Button
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  startIcon={<SyncRounded />}
                  onClick={handleSyncContact}
                  disabled={loadingSyncContact}
                  style={{ marginTop: "1rem" }}
                >
                  {loadingSyncContact
                    ? i18n.t("contacts.importExportFile.syncContact.buttonLoading")
                    : i18n.t("contacts.importExportFile.syncContact.button")}
                </Button>
              </Paper>
            </Box>
          </TabPanel>
          <TabPanel value={value} index={2}>
            <p>{i18n.t("contacts.importExportFile.exportContact.message")}</p>

            <Box
              display={"flex"}
              flexDirection={"column"}
              style={{ gap: "1rem" }}
            >
              <Paper variant="outlined" className={classes.body}>
                <Typography variant="h6">
                  {i18n.t("contacts.importExportFile.exportContact.labels")}
                </Typography>
                <FormGroup
                  row
                  style={{
                    gap: "1rem",
                    paddingLeft: "0.5rem",
                    marginTop: "0.5rem",
                  }}
                >
                  {labelList && labelList.length > 0
                    ? labelList.map((label) => (
                        <FormControlLabel
                          key={label.id}
                          control={
                            <Checkbox
                              value={label.labelSlug}
                              checked={selectedLabelIds.includes(
                                label.labelSlug
                              )}
                              style={{
                                backgroundColor: opacityColor(label.color, 10),
                                marginRight: 5,
                                color: label.color,
                                borderColor: label.color,
                              }}
                              onChange={handleLabelChange}
                              name={label.name}
                            />
                          }
                          label={label.name}
                        />
                      ))
                    : null}
                </FormGroup>
              </Paper>

              <Paper variant="outlined" className={classes.body}>
                <Typography variant="h6">
                  {i18n.t(
                    "contacts.importExportFile.exportContact.groupContacts"
                  )}
                </Typography>
                <RadioGroup
                  style={{
                    gap: "0.5rem",
                    paddingLeft: "0.5rem",
                    marginTop: "0.5rem",
                  }}
                  value={contactType}
                  onChange={(e) => setContactType(e.target.value)}
                >
                  <FormControlLabel
                    value="all"
                    control={<Radio />}
                    label={i18n.t(
                      "contacts.importExportFile.exportContact.all"
                    )}
                  />
                  <FormControlLabel
                    value="contacts"
                    control={<Radio />}
                    label={i18n.t(
                      "contacts.importExportFile.exportContact.contactOnly"
                    )}
                  />
                  <FormControlLabel
                    value="groups"
                    control={<Radio />}
                    label={i18n.t(
                      "contacts.importExportFile.exportContact.groupOnly"
                    )}
                  />
                </RadioGroup>
              </Paper>

              <Button
                variant="contained"
                color="primary"
                className={classes.button}
                startIcon={loadingExportFile ? <CircularProgress size={20} /> : <CloudDownload />}
                onClick={handleExportFile}
                disabled={loadingExportFile}
              >
                {i18n.t("contacts.importExportFile.exportContact.button")}
              </Button>
            </Box>
          </TabPanel>
        </Paper>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleImportFileDialogClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ContactImportExportModal;
