import React, { useContext, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { AuthContext } from "../../context/Auth/AuthContext";
import { getBackendUrl, getSandBoxIframe, getChatBotUrl, getWhiteLabelLogoChatAi } from "../../config";
import api from "../../services/api";
import toastError from "../../errors/toastError";
import { Grid, Typography } from "@material-ui/core";

import { getChatBotStatus } from "../../config";
import { i18n } from "../../translate/i18n";
import { useSettingsContext } from "../../context/Setting/SettingsContext";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    height: "100%",
    width: "100%",
  },
  title: {
    fontSize: "2.5rem",
    fontWeight: "bold",
    color: "#57BB89",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.5rem",
    },
  },
  subtitle: {
    fontSize: "1.5rem",
    fontWeight: "bold",
    color: "#57BB89",
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.8rem",
    },
  },
  logo: {
    height: "25%",
    [theme.breakpoints.down("sm")]: {
      height: "10%",
    },
  },
}));

const ChatAi = () => {
  const classes = useStyles();
  const { user } = useContext(AuthContext);

  const email = user.email;
  const baseUrl = getBackendUrl();

  const { settings } = useSettingsContext();

  const getUserApiToken = () => {
    if (!Array.isArray(settings) || settings.length === 0) {
      return null;
    }
    const setting = settings && settings.length > 0 && settings.find((s) => s.key === "userApiToken");
    return setting ? setting.value : null;
  };

  const getUniqueId = () => {
    if (!Array.isArray(settings) || settings.length === 0) {
      return null;
    }
    const setting = settings.find((s) => s.key === "uniqueId");
    return setting ? setting.value : null;
  };

  return (
    <div className={classes.root}>
      {getChatBotStatus() === "true" ? (
        <iframe
          src={`${getChatBotUrl()}&email=${email}&apiToken=${getUserApiToken()}&baseUrl=${baseUrl}&uniqueId=${getUniqueId()}`}
          sandbox={getSandBoxIframe()}
          title="Chat"
          width="100%"
          height="100%"
          frameBorder="0"
        />
      ) : (
        <Grid container spacing={3} justify="center">
          <Grid item xs={12} align="center">
            <img
              className={classes.logo}
              src={`/${getWhiteLabelLogoChatAi()}`}
              alt="Logo Waapy Chat"
            />
            <Typography variant="h1" className={classes.title}>
              {i18n.t("chatAi.comingSoon.title")}
            </Typography>
            <Typography variant="h2" className={classes.subtitle}>
              {i18n.t("chatAi.comingSoon.subtitle")}
            </Typography>
          </Grid>
        </Grid>
      )}
    </div>
  );
};

export default ChatAi;
