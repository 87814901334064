import React, { useEffect, useState } from "react";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import toastError from "../../errors/toastError";
import api from "../../services/api";
import { i18n } from "../../translate/i18n";

const TypeBotSelect = ({ selectedTypeBotId, onChange }) => {
  const [typeBot, setTypeBot] = useState([]);

  useEffect(() => {
    (async () => {
      try {
        const { data } = await api.get("/integrations/types/typebot");
        setTypeBot(data);
      } catch (err) {
        toastError(err);
      }
    })();

    return () => {};
  }, []);

  const handleChange = (e) => {
    onChange(e.target.value);
  };

  return (
    <div style={{ marginTop: 6 }}>
      <FormControl fullWidth margin="dense" variant="outlined">
        <InputLabel id="demo-simple-select-outlined-label">{i18n.t("typeBotSelect.inputLabel")}</InputLabel>
        <Select
          labelId="demo-simple-select-outlined-label"
          id="demo-simple-select-outlined"
          labelWidth={90}
          value={selectedTypeBotId}
          onChange={handleChange}
          MenuProps={{
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left",
            },
            transformOrigin: {
              vertical: "top",
              horizontal: "left",
            },
            getContentAnchorEl: null,
          }}
        >
          <MenuItem value={null}>{i18n.t("typeBotSelect.noTypeBot")}</MenuItem>
          {typeBot &&
            typeBot.length > 0 &&
            typeBot.map((chatFlow) => (
              <MenuItem key={chatFlow.id} value={chatFlow.id}>
                {chatFlow.name}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    </div>
  );
};

export default TypeBotSelect;
