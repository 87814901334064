import React, { useEffect, useState } from "react";
import QRCode from "qrcode.react";
import openSocket from "../../services/socket-io";
import toastError from "../../errors/toastError";
import { Settings, MoreVert } from "@material-ui/icons";

import {
  Dialog,
  DialogContent,
  Grid,
  Paper,
  Typography,
} from "@material-ui/core";
import { i18n } from "../../translate/i18n";
import api from "../../services/api";

const QrcodeModal = ({ open, onClose, whatsAppId }) => {
  const [qrCode, setQrCode] = useState("");

  useEffect(() => {
    const fetchSession = async () => {
      if (!whatsAppId) return;

      try {
        const { data } = await api.get(`/whatsapp/${whatsAppId}`);
        setQrCode(data.qrcode);
      } catch (err) {
        toastError(err);
      }
    };
    fetchSession();
  }, [whatsAppId]);

  useEffect(() => {
    if (!whatsAppId) return;
    const socket = openSocket();

    socket.on("whatsappSession", (data) => {
      if (data.action === "update" && data.session.id === whatsAppId) {
        setQrCode(data.session.qrcode);
      }

      if (data.action === "update" && data.session.qrcode === "") {
        onClose();
      }
    });

    return () => {
      socket.disconnect();
    };
  }, [whatsAppId, onClose]);

  return (
    <Dialog open={open} onClose={onClose}  fullWidth scroll="paper">
      <DialogContent style={{ padding: 16 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Typography variant="h6" color="black" gutterBottom>
              {i18n.t("qrCode.title")}
            </Typography>
            <Paper variant="outlined" style={{ padding: 16 }}>
              <ol style={{ padding: "0 0 0 16px", margin: 0 }}>
                <li>Open WhatsApp on your phone</li>
                <li>
                  Tap More options on Android or Settings on iPhone <Settings />{" "}
                  <MoreVert />
                </li>
                <li>Tap Linked devices and then Link Devices</li>
                <li>Point your phone at this screen to capture the QR Code</li>
              </ol>
            </Paper>
          </Grid>
          <Grid item xs={12} md={6}>
            {qrCode ? (
              <QRCode
                value={qrCode}
                size={256}
                renderAs="svg"
                style={{ width: "100%", height: "100%" }}
              />
            ) : (
              <span>Waiting for QR Code</span>
            )}
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default React.memo(QrcodeModal);
