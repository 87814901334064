import React, { useEffect, useState } from "react";
import FormControl from "@material-ui/core/FormControl";
import {
  Box,
  Checkbox,
  FormControlLabel,
  Paper,
  Typography,
} from "@material-ui/core";
import { i18n } from "../../translate/i18n";
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  withStyles,
  Badge,
} from "@material-ui/core";
import { Tune } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  body: {
    padding: "1.5rem",
  },
}));

const StyleBadge = withStyles((theme) => ({
  badge: {
    backgroundColor: "red",
    color: "red",
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    animation: "$pulse 1.5s infinite",
  },
  "@keyframes pulse": {
    "0%": {
      transform: "scale(1)",
    },
    "50%": {
      transform: "scale(1.2)",
    },
    "100%": {
      transform: "scale(1)",
    },
  },
}))(Badge);

const TicketsFilterSelect = ({
  userQueues,
  userLabels,
  selectedQueueIds = [],
  selectedLabelIds = [],
  onChange,
}) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [tempSelectedQueueIds, setTempSelectedQueueIds] = useState([]);
  const [tempSelectedLabelIds, setTempSelectedLabelIds] = useState([]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    setTempSelectedQueueIds(selectedQueueIds);
    setTempSelectedLabelIds(selectedLabelIds);
  }, [selectedQueueIds, selectedLabelIds]);

  const handleSave = () => {
    localStorage.setItem(
      "selectedQueueIds",
      JSON.stringify(tempSelectedQueueIds)
    );
    localStorage.setItem(
      "selectedLabelIds",
      JSON.stringify(tempSelectedLabelIds)
    );

    onChange({
      selectedQueueIds: tempSelectedQueueIds,
      selectedLabelIds: tempSelectedLabelIds,
    });
    setOpen(false);
  };

  const handleQueueChange = (id) => {
    setTempSelectedQueueIds((prev) =>
      prev.includes(id)
        ? prev.filter((queueId) => queueId !== id)
        : [...prev, id]
    );
  };

  const handleLabelChange = (id) => {
    setTempSelectedLabelIds((prev) =>
      prev.includes(id)
        ? prev.filter((labelId) => labelId !== id)
        : [...prev, id]
    );
  };

  const selectAllQueues = () => {
    setTempSelectedQueueIds(userQueues.map((queue) => queue.id));
  };

  const unselectAllQueues = () => {
    setTempSelectedQueueIds([]);
  };

  const renderQueues = () => (
    <>
      <Paper variant="outlined" className={classes.body}>
        <Box
          justifyContent={"space-between"}
          display="flex"
          alignItems={"center"}
        >
          <Typography variant="h6">
            {i18n.t("ticketsFilterSelect.queues")}
          </Typography>
          <div>
            <a href="#" onClick={selectAllQueues}>
              {i18n.t("ticketsFilterSelect.selectAll")}
            </a>
            {" | "}
            <a href="#" onClick={unselectAllQueues}>
              {i18n.t("ticketsFilterSelect.unselectAll")}
            </a>
          </div>
        </Box>
        <div>
          {userQueues?.length > 0 &&
            userQueues.map((queue) => (
              <FormControlLabel
                key={queue.id}
                control={
                  <Checkbox
                    style={{ color: queue.color }}
                    size="small"
                    color="primary"
                    checked={tempSelectedQueueIds.includes(queue.id)}
                    onChange={() => handleQueueChange(queue.id)}
                  />
                }
                label={queue.name}
              />
            ))}
        </div>
      </Paper>
    </>
  );

  const selectAllLabels = () => {
    setTempSelectedLabelIds(userLabels.map((label) => label.id));
  };

  const unselectAllLabels = () => {
    setTempSelectedLabelIds([]);
  };

  const renderLabels = () => (
    <>
      <Paper variant="outlined" className={classes.body}>
        <Box
          justifyContent={"space-between"}
          display="flex"
          alignItems={"center"}
        >
          <Typography variant="h6">
            {i18n.t("ticketsFilterSelect.labels")}
          </Typography>
          <div>
            <a href="#" onClick={selectAllLabels}>
              {i18n.t("ticketsFilterSelect.selectAll")}
            </a>
            {" | "}
            <a href="#" onClick={unselectAllLabels}>
              {i18n.t("ticketsFilterSelect.unselectAll")}
            </a>
          </div>
        </Box>
        <div>
          {userLabels?.length > 0 &&
            userLabels.map((label) => (
              <FormControlLabel
                key={label.id}
                control={
                  <Checkbox
                    style={{ color: label.color }}
                    size="small"
                    color="primary"
                    checked={tempSelectedLabelIds.includes(label.id)}
                    onChange={() => handleLabelChange(label.id)}
                  />
                }
                label={label.name}
              />
            ))}
        </div>
      </Paper>
    </>
  );

  const handleReset = () => {
    setTempSelectedQueueIds(userQueues);
    setTempSelectedLabelIds(userLabels);

    localStorage.setItem(
      "selectedQueueIds",
      JSON.stringify(tempSelectedQueueIds)
    );
    localStorage.setItem(
      "selectedLabelIds",
      JSON.stringify(tempSelectedLabelIds)
    );

    onChange({
      selectedQueueIds: userQueues.map((queue) => queue.id),
      selectedLabelIds: userLabels.map((label) => label.id),
    });
    setOpen(false);
  };

  return (
    <div>
      <Button variant="outlined" onClick={handleOpen}>
        {i18n.t("ticketsFilterSelect.openFilter")}
        {userQueues?.length + userLabels?.length > 0 &&
          (selectedLabelIds.length + selectedQueueIds.length !==
          userLabels?.length + userQueues?.length ? (
            <StyleBadge
              overlap="circular"
              anchorOrigin={{ vertical: "top", horizontal: "right" }}
              variant="dot"
            >
              <Tune fontSize="small" style={{ marginLeft: "0.5rem" }} />
            </StyleBadge>
          ) : (
            <Tune fontSize="small" style={{ marginLeft: "0.5rem" }} />
          ))}
      </Button>
      <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
        <DialogTitle>{i18n.t("ticketsFilterSelect.title")}</DialogTitle>
        <DialogContent dividers>
          <FormControl
            component="fieldset"
            style={{ gap: "1rem", display: "flex", flexDirection: "column" }}
          >
            {renderQueues()}
            {renderLabels()}
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Box display="flex" justifyContent="space-between" width="100%">
            <Button onClick={handleReset} color="secondary">
              {i18n.t("ticketsFilterSelect.reset")}
            </Button>
            <div>
              <Button onClick={handleClose} color="primary">
                {i18n.t("ticketsFilterSelect.cancel")}
              </Button>
              <Button onClick={handleSave} color="primary">
                {i18n.t("ticketsFilterSelect.save")}
              </Button>
            </div>
          </Box>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default TicketsFilterSelect;
