import {
  Box,
  Button,
  Chip,
  CircularProgress,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@material-ui/core";
import { useEffect, useState } from "react";
import { i18n } from "../../translate/i18n.js";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { green, teal } from "@material-ui/core/colors";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import api from "../../services/api.js";
import toastError from "../../errors/toastError.js";
import { toast } from "react-toastify";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";

const StyledTabs = withStyles({
  indicator: {
    display: "flex",
    justifyContent: "center",
    backgroundColor: teal[500],
    height: 3,
    "& > span": {
      width: "100%",
      backgroundColor: teal[500],
    },
  },
})((props) => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />);

const StyledTab = withStyles((theme) => ({
  root: {
    textTransform: "none",
    color: teal[500],
    borderColor: teal[500],
    fontWeight: theme.typography.fontWeightBold,
    fontSize: theme.typography.pxToRem(15),
    marginRight: theme.spacing(1),
    "&:focus": {
      opacity: 1,
    },
  },
}))((props) => <Tab disableRipple {...props} />);

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  backgroundColor: {
    backgroundColor: theme.palette.background.paper,
  },
  paper: {
    padding: theme.spacing(3),
    display: "flex",
    alignItems: "center",
    marginBottom: 12,
  },
  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box pt={3} pb={3}>
          <Typography component={"div"}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const VoiceToTextSchema = Yup.object().shape({
  provider: Yup.string().required("Required"),
  model: Yup.string().required("Required"),
  apiKey: Yup.string().required("Required"),
});

const AIAssistantsTab = ({ settings }) => {
  const initialState = {
    provider: "",
    model: "",
    status: "disabled",
    apiKey: "",
  };

  const classes = useStyles();
  const [voiceToTextForm, setVoiceToTextForm] = useState(initialState);
  const [voiceToTextProviders, setVoiceToTextProviders] = useState([]);
  const [value, setValue] = useState(0);
  const [showToken, setShowToken] = useState(false);

  useEffect(() => {
    const fetchVoiceToTextProviders = async () => {
      try {
        const { data } = await api.get(`/ai-assistant/voice-to-text/providers`);

        setVoiceToTextProviders(data);
      } catch (err) {
        toastError(err);
      }
    };

    fetchVoiceToTextProviders();
  }, []);

  useEffect(() => {
    if (settings && settings.length > 0) {
      setVoiceToTextForm({
        provider: settings.find((s) => s.key === "voiceToTextProvider")?.value,
        model: settings.find((s) => s.key === "voiceToTextModel")?.value,
        status: settings.find((s) => s.key === "voiceToTextStatus")?.value,
        apiKey: settings.find((s) => s.key === "voiceToTextApiKey")?.value,
      });
    }
  }, [settings]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleSubmitForm = async (values) => {
    try {
      await api.post("/ai-assistant/voice-to-text", values);

      toast.success(i18n.t("AIAssistantsTab.success"));
    } catch (err) {
      toastError(err);
    }
  };

  const handleClickShowToken = () => {
    setShowToken(!showToken);
  };

  const handleMouseDownToken = (event) => {
    event.preventDefault();
  };

  return (
    <Paper square className={classes.paper}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h6">
            {i18n.t("AIAssistantsTab.title")}
            <Chip
              label={i18n.t("AIAssistantsTab.beta")}
              color="primary"
              size="small"
              style={{ marginLeft: "10px" }}
            />
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <div className={classes.backgroundColor}>
            <StyledTabs
              value={value}
              onChange={handleChange}
              aria-label="styled tabs example"
            >
              <StyledTab label={i18n.t("AIAssistantsTab.voiceToText.title")} />
            </StyledTabs>

            <TabPanel value={value} index={0}>
              <Typography variant="body1" style={{ marginBottom: 16 }}>
                {i18n.t("AIAssistantsTab.voiceToText.description")}
              </Typography>
              {voiceToTextProviders && voiceToTextProviders.length > 0 ? (
                <Formik
                  initialValues={voiceToTextForm}
                  enableReinitialize={true}
                  validationSchema={VoiceToTextSchema}
                  onSubmit={(values, actions) => {
                    setTimeout(() => {
                      handleSubmitForm(values);
                      actions.setSubmitting(false);
                    }, 400);
                  }}
                >
                  {({ values, errors, touched, isSubmitting }) => (
                    <Form>
                      <Grid container spacing={2}>
                        <Grid item xs={12} md={4}>
                          <FormControl variant="outlined" fullWidth>
                            <InputLabel id="provider-label">
                              {i18n.t(
                                "AIAssistantsTab.voiceToText.form.provider"
                              )}
                            </InputLabel>
                            <Select
                              labelWidth={80}
                              value={values.provider}
                              MenuProps={{
                                anchorOrigin: {
                                  vertical: "bottom",
                                  horizontal: "left",
                                },
                                transformOrigin: {
                                  vertical: "top",
                                  horizontal: "left",
                                },
                                getContentAnchorEl: null,
                              }}
                              onChange={(e) => {
                                setVoiceToTextForm({
                                  ...voiceToTextForm,
                                  provider: e.target.value,
                                });
                              }}
                            >
                              {voiceToTextProviders.map((provider) => (
                                <MenuItem
                                  key={provider.name}
                                  value={provider.name}
                                >
                                  {provider.name}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <FormControl variant="outlined" fullWidth>
                            <InputLabel id="model-label">
                              {i18n.t("AIAssistantsTab.voiceToText.form.model")}
                            </InputLabel>
                            <Select
                              labelWidth={80}
                              value={values.model}
                              MenuProps={{
                                anchorOrigin: {
                                  vertical: "bottom",
                                  horizontal: "left",
                                },
                                transformOrigin: {
                                  vertical: "top",
                                  horizontal: "left",
                                },
                                getContentAnchorEl: null,
                              }}
                              onChange={(e) => {
                                setVoiceToTextForm({
                                  ...voiceToTextForm,
                                  model: e.target.value,
                                });
                              }}
                              disabled={!values.provider}
                            >
                              {voiceToTextProviders
                                .filter(
                                  (provider) =>
                                    provider.name === values.provider
                                )
                                .map((provider) =>
                                  provider.model.map((model) => (
                                    <MenuItem
                                      key={model.name}
                                      value={model.name}
                                    >
                                      {model.name}
                                    </MenuItem>
                                  ))
                                )}
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <FormControl variant="outlined" fullWidth>
                            <InputLabel id="status-label">
                              {i18n.t(
                                "AIAssistantsTab.voiceToText.form.status"
                              )}
                            </InputLabel>
                            <Select
                              labelWidth={80}
                              value={values.status}
                              MenuProps={{
                                anchorOrigin: {
                                  vertical: "bottom",
                                  horizontal: "left",
                                },
                                transformOrigin: {
                                  vertical: "top",
                                  horizontal: "left",
                                },
                                getContentAnchorEl: null,
                              }}
                              onChange={(e) => {
                                setVoiceToTextForm({
                                  ...voiceToTextForm,
                                  status: e.target.value,
                                });
                              }}
                            >
                              <MenuItem value="disabled">
                                {i18n.t(
                                  "AIAssistantsTab.voiceToText.form.disabled"
                                )}
                              </MenuItem>
                              <MenuItem value="enabled">
                                {i18n.t(
                                  "AIAssistantsTab.voiceToText.form.enabled"
                                )}
                              </MenuItem>
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                          <Field
                            as={TextField}
                            name="apiKey"
                            label={i18n.t(
                              "AIAssistantsTab.voiceToText.form.apiKey"
                            )}
                            variant="outlined"
                            fullWidth
                            helperText={touched.apiKey ? errors.apiKey : ""}
                            error={touched.apiKey && Boolean(errors.apiKey)}
                            value={showToken ? values.apiKey : "***********************"}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton
                                    aria-label="toggle token visibility"
                                    onClick={handleClickShowToken}
                                    onMouseDown={handleMouseDownToken}
                                  >
                                    {showToken ? (
                                      <Visibility />
                                    ) : (
                                      <VisibilityOff />
                                    )}
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            disabled={isSubmitting}
                          >
                            {i18n.t("AIAssistantsTab.voiceToText.buttons.save")}
                            {isSubmitting && (
                              <CircularProgress
                                size={24}
                                className={classes.buttonProgress}
                              />
                            )}
                          </Button>
                        </Grid>
                      </Grid>
                    </Form>
                  )}
                </Formik>
              ) : (
                <Typography variant="body1" style={{ marginBottom: 16 }}>
                  {i18n.t("AIAssistantsTab.voiceToText.noProviders")}
                </Typography>
              )}
            </TabPanel>
          </div>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default AIAssistantsTab;
