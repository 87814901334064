import React from "react";
import { BrowserRouter, Switch } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import LoggedInLayout from "../layout";
import Dashboard from "../pages/Dashboard/";
import Tickets from "../pages/Tickets/";
import Signup from "../pages/Signup/";
import Login from "../pages/Login/";
import Connections from "../pages/Connections/";
import Settings from "../pages/Settings/";
import Users from "../pages/Users";
import Contacts from "../pages/Contacts/";
import QuickAnswers from "../pages/QuickAnswers/";
import Queues from "../pages/Queues/";
import { AuthProvider } from "../context/Auth/AuthContext";
import { WhatsAppsProvider } from "../context/WhatsApp/WhatsAppsContext";
import Route from "./Route";
import ChatAi from "../pages/ChatAi";
import ResetPassword from "../pages/ResetPassword";
import Permissions from "../pages/Permissions";
import Reports from "../pages/Reports";
import Integrations from "../pages/Integrations";
import Labels from "../pages/Labels";
import { PermissionsProvider } from "../context/Permission/PermissionsContext";
import { SettingsProvider } from "../context/Setting/SettingsContext";
import Templates from "../pages/Templates";
import AutoFollowUpRule from "../pages/AutoFollowUpRule";

const Routes = () => {
  return (
    <BrowserRouter>
      <AuthProvider>
        <Switch>
          <Route exact path="/login" component={Login} />
          <Route exact path="/signup" component={Signup} />
          <Route exact path="/reset-password" component={ResetPassword} />
          <SettingsProvider>
            <WhatsAppsProvider>
              <PermissionsProvider>
                <LoggedInLayout>
                  <Route exact path="/" component={Dashboard} isPrivate />
                  <Route
                    exact
                    path="/tickets/:ticketId?"
                    component={Tickets}
                    isPrivate
                  />
                  <Route
                    exact
                    path="/connections"
                    component={Connections}
                    isPrivate
                  />
                  <Route
                    exact
                    path="/contacts"
                    component={Contacts}
                    isPrivate
                  />
                  <Route exact path="/users" component={Users} isPrivate />
                  <Route
                    exact
                    path="/quickAnswers"
                    component={QuickAnswers}
                    isPrivate
                  />
                  <Route exact path="/chatAi" component={ChatAi} isPrivate />
                  <Route
                    exact
                    path="/permissions"
                    component={Permissions}
                    isPrivate
                  />
                  <Route exact path="/reports" component={Reports} isPrivate />
                  <Route
                    exact
                    path="/Settings"
                    component={Settings}
                    isPrivate
                  />
                  <Route exact path="/integrations" component={Integrations} isPrivate />
                  <Route exact path="/Queues" component={Queues} isPrivate />
                  <Route exact path="/labels" component={Labels} isPrivate />
                  <Route exact path="/templates" component={Templates} isPrivate />
                  <Route exact path="/auto-follow-up" component={AutoFollowUpRule} isPrivate />
                </LoggedInLayout>
              </PermissionsProvider>
            </WhatsAppsProvider>
          </SettingsProvider>
        </Switch>
        <ToastContainer autoClose={3000} />
      </AuthProvider>
    </BrowserRouter>
  );
};

export default Routes;
