import React, { useState, useContext, useEffect } from "react";
import { Link as RouterLink } from "react-router-dom";

import {
  Button,
  CssBaseline,
  TextField,
  Grid,
  Box,
  Typography,
  Container,
  InputAdornment,
  IconButton,
  Link,
} from "@material-ui/core";

import { Visibility, VisibilityOff } from "@material-ui/icons";

import { makeStyles } from "@material-ui/core/styles";

import { i18n } from "../../translate/i18n";

import { AuthContext } from "../../context/Auth/AuthContext";

import {
  getWhiteLabelLogo,
  getWhiteLabelName,
  getWhiteLabelCompanyName,
  getWhiteLabelCompanyUrl,
  getWhiteLabelImageBackground,
} from "../../config";
import api from "../../services/api";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
    background: "linear-gradient(135deg, #E2F397 0%, #58BA89 100%)",
    display: "flex",
    justifyContent: "center",
    alignItems: "stretch",
  },
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    textTransform: "none",
    fontWeight: "bold",
    color: "#fff",
  },
  bgLogin: {
    backgroundColor: "#fff",
    padding: "3rem",
    borderRadius: "10px",
    height: "100%",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      padding: "1rem",
    },
  },
  bgSecond: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    borderRadius: "10px",
  },
  bgSecondImage: {
    width: "650px",
    backgroundSize: "cover",
  },
  logo: {
    width: "150px",
    marginBottom: "2rem",
  },
  welcomeBack: {
    fontWeight: "bold",
    marginBottom: "2rem",
    color: "#1E1E1E",
    fontSize: "35px",
  },
  footerText: {
    color: "#1E1E1E",
    fontSize: "12px",
    fontWeight: "lighter",
  },
  hideOnMobile: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  input: {
    backgroundColor: "#fff",
    "&::-webkit-input-placeholder": {
      color: "#fff",
    },
    "&::-moz-placeholder": {
      color: "#fff",
    },
    "&:-ms-input-placeholder": {
      color: "#fff",
    },
    "&:-moz-placeholder": {
      color: "#fff",
    },
  },
}));

const Login = () => {
  const classes = useStyles();

  const [user, setUser] = useState({ email: "", password: "" });
  const [showPassword, setShowPassword] = useState(false);
  const [userCreation, setUserCreation] = useState("disabled");

  const { handleLogin } = useContext(AuthContext);

  const handleChangeInput = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };

  const handlSubmit = (e) => {
    e.preventDefault();
    handleLogin(user);
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      const getUserCreation = async () => {
        try {
          const { data } = await api.get("/s/user-creation");
          setUserCreation(data);
        } catch (err) {
          console.log(err);
        }
      };
      getUserCreation();
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, []);

  return (
    <Box component="span">
      <CssBaseline />
      <div className={classes.root}>
        <Grid container>
          <Grid container xs={12} md={6} lg={4}>
            <Container
              component="main"
              style={{ paddingTop: "1.5rem", paddingBottom: "1.5rem" }}
            >
              <div className={classes.bgLogin}>
                <div className={classes.paper}>
                  <img
                    className={classes.logo}
                    src={`/${getWhiteLabelLogo()}`}
                    alt={`Logo ${getWhiteLabelName()}`}
                  />
                  <Typography
                    component="h1"
                    variant="h5"
                    className={classes.welcomeBack}
                  >
                    Welcome Back
                  </Typography>
                  <form
                    className={classes.form}
                    noValidate
                    onSubmit={handlSubmit}
                  >
                    <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      id="email"
                      label={i18n.t("login.form.email")}
                      name="email"
                      value={user.email}
                      onChange={handleChangeInput}
                      autoComplete="email"
                      autoFocus
                      InputProps={{
                        classes: { input: classes.input },
                      }}
                    />
                    <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      className={classes.input}
                      fullWidth
                      name="password"
                      label={i18n.t("login.form.password")}
                      id="password"
                      value={user.password}
                      onChange={handleChangeInput}
                      autoComplete="current-password"
                      type={showPassword ? "text" : "password"}
                      InputProps={{
                        classes: { input: classes.input },
                        endAdornment: (
                          <InputAdornment
                            position="end"
                            classes={{ root: classes.input }}
                          >
                            <IconButton
                              classes={{ root: classes.input }}
                              aria-label="toggle password visibility"
                              onClick={() => setShowPassword((e) => !e)}
                            >
                              {showPassword ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      color="primary"
                      className={classes.submit}
                    >
                      {i18n.t("login.buttons.submit")}
                    </Button>
                    <Grid container>
                      <Grid item>
                        {userCreation === "enabled" && (
                          <>
                            <Link
                              href="#"
                              variant="body2"
                              component={RouterLink}
                              to="/signup"
                            >
                              {i18n.t("login.buttons.register")}
                            </Link>
                            <br />
                          </>
                        )}
                        <Link
                          href="#"
                          variant="body2"
                          component={RouterLink}
                          to="/reset-password"
                        >
                          {i18n.t("login.buttons.forgotPassword")}
                        </Link>
                      </Grid>
                    </Grid>
                  </form>
                </div>
                <Box mt={8}>
                  <Typography
                    variant="body2"
                    className={classes.footerText}
                    align="center"
                  >
                    {"Copyright © "}
                    {new Date().getFullYear()}
                    {". "}
                    <Link color="inherit" href={getWhiteLabelCompanyUrl()}>
                      {getWhiteLabelCompanyName()}
                    </Link>
                    {"."}
                  </Typography>
                </Box>
              </div>
            </Container>
          </Grid>
          <Grid container className={classes.hideOnMobile} md={6} lg={8}>
            <Container
              component="main"
              style={{ paddingTop: "1.5rem", paddingBottom: "1.5rem" }}
            >
              <div className={classes.bgSecond}>
                <img
                  src={`/${getWhiteLabelImageBackground()}`}
                  alt="Background Login"
                  className={classes.bgSecondImage}
                />
              </div>
            </Container>
          </Grid>
        </Grid>
      </div>
    </Box>
  );
};

export default Login;
