import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useMemo,
} from "react";

import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import { toast } from "react-toastify";
import ReactQuill from "react-quill-new";
import "react-quill-new/dist/quill.snow.css";
import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import CircularProgress from "@material-ui/core/CircularProgress";

import { i18n } from "../../translate/i18n";

import api from "../../services/api";
import toastError from "../../errors/toastError";
import {
  Avatar,
  Card,
  CardContent,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
} from "@material-ui/core";
import { DeleteOutline, Visibility } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginRight: theme.spacing(1),
    flex: 1,
  },

  extraAttr: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  btnWrapper: {
    position: "relative",
  },

  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },

  extraAttr: {
    height: "100%",
    minHeight: "450px",
  },

  input: {
    display: "none",
  },
}));

const ContactSchema = Yup.object().shape({
  title: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Required"),
});

const NoteModal = ({
  open,
  onClose,
  contactId,
  initialValues,
  onSave,
  ticketId,
  note,
}) => {
  const classes = useStyles();
  const isMounted = useRef(true);

  const initialState = {
    title: "",
    body: "",
    files: [],
  };

  const [noteForm, setNoteForm] = useState(initialState);
  const [isUploading, setIsUploading] = useState(false);
  const [fileNoIds, setFileNoIds] = useState([]);

  const quill = useRef();
  const fileInputRef = useRef(null);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    const fetchNote = async () => {
      if (initialValues) {
        setNoteForm((prevState) => {
          return { ...prevState, ...initialValues };
        });
      }

      if (!note) return;

      try {
        const { data } = await api.get(`/notes/${note.id}`);
        if (isMounted.current) {
          setNoteForm({
            title: data.title,
            body: data.body,
            files: data.files,
          });
        }
      } catch (err) {
        toastError(err);
      }
    };

    fetchNote();
  }, [contactId, ticketId, note, open, initialValues]);

  const handleClose = () => {
    onClose();
    setNoteForm(initialState);
    setFileNoIds([]);
    note = null;
  };

  const handleSaveNote = async (values) => {
    try {
      if (note) {
        await api.put(`/notes/${note.id}`, values);
        handleClose();
      } else {
        const formData = new FormData();

        formData.append("title", values.title);
        formData.append("body", values.body);
        formData.append("ticketId", ticketId);

        if (fileNoIds && fileNoIds.length > 0) {
          fileNoIds.forEach((file) => {
            formData.append("files", file);
          });
        }

        const { data } = await api.post("/notes", formData);
        if (onSave) {
          onSave(data);
        }
        handleClose();
      }
      toast.success(i18n.t("noteModal.success"));
    } catch (err) {
      toastError(err);
    }
  };

  const imageHandler = useCallback(() => {
    const input = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute("accept", "image/*");
    input.click();
    input.onchange = async () => {
      if (input !== null && input.files !== null) {
        const file = input.files[0];
      }
    };
  }, []);

  const modules = useMemo(
    () => ({
      toolbar: {
        container: [
          [{ header: [2, 3, 4, false] }],
          ["bold", "italic", "underline", "strike"],
          [{ list: "ordered" }, { list: "bullet" }],
          ["clean"],
        ],
        handlers: {
          image: imageHandler,
        },
      },
      clipboard: {
        matchVisual: true,
      },
    }),
    [imageHandler]
  );

  const handleUploadfiles = async () => {
    const fileInput = fileInputRef.current;
    if (!fileInput || !fileInput.files || fileInput.files.length === 0) {
      console.error("No files selected");
      return;
    }

    const uploadedFiles = Array.from(fileInput.files);

    if (note) {
      setIsUploading(true);
      const formData = new FormData();

      uploadedFiles.forEach((file) => formData.append("files", file));

      try {
        await api.post(`/notes/${note.id}/files`, formData);

        const { data } = await api.get(`/notes/${note.id}`);
        setNoteForm(data);

        toast.success(i18n.t("noteModal.successUploadFiles"));
      } catch (error) {
        toastError(error);
      } finally {
        setIsUploading(false);
      }
    } else {
      try {
        uploadedFiles.forEach((file) => {
          if (fileNoIds.find((f) => f.name === file.name)) {
            toast.error(i18n.t("noteModal.errorFileExists"));
          } else {
            fileNoIds.push(file);
          }
        });

        setFileNoIds([...fileNoIds]);
      } catch (error) {
        console.error("Error handling file upload:", error);
      }
    }

    // Clear the file input after processing
    fileInput.value = "";
  };

  const handleDeleteImage = async (index) => {
    if (!note) {
      fileNoIds.splice(index, 1);
      setFileNoIds([...fileNoIds]);
      return;
    }

    try {
      await api.delete(`/notes/${note.id}/files/${noteForm.files[index].id}`);
      noteForm.files.splice(index, 1);
      setNoteForm({ ...noteForm, files: [...noteForm.files] });
      toast.success(i18n.t("noteModal.successDeleteFiles"));
    } catch (err) {
      toastError(err);
    }
  };

  return (
    <div className={classes.root}>
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth
        maxWidth="lg"
        scroll="paper"
      >
        <DialogTitle id="form-dialog-title">
          {note
            ? `${i18n.t("noteModal.title.edit")}`
            : `${i18n.t("noteModal.title.add")}`}
        </DialogTitle>
        <Formik
          initialValues={noteForm}
          enableReinitialize={true}
          validationSchema={ContactSchema}
          onSubmit={(values, actions) => {
            setTimeout(() => {
              handleSaveNote(values);
              actions.setSubmitting(false);
            }, 400);
          }}
        >
          {({ values, errors, touched, isSubmitting, setFieldValue }) => (
            <Form>
              <DialogContent dividers>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Field
                      as={TextField}
                      label={i18n.t("noteModal.form.title")}
                      name="title"
                      fullWidth
                      autoFocus
                      error={touched.title && Boolean(errors.title)}
                      helperText={touched.title && errors.title}
                      variant="outlined"
                      margin="dense"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <div className={classes.extraAttr}>
                      <ReactQuill
                        ref={(el) => (quill.current = el)}
                        style={{ height: "400px" }}
                        name="body"
                        theme="snow"
                        placeholder="Write a note..."
                        modules={modules}
                        value={values.body}
                        onChange={(value) => {
                          setFieldValue("body", value);
                        }}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={12}>
                    <Card variant="outlined" style={{ borderColor: "#C4C4C4" }}>
                      <CardContent>
                        <Typography variant="subtitle1">
                          {i18n.t("noteModal.form.files")}
                        </Typography>
                        {note && noteForm.files && noteForm.files.length > 0 ? (
                          <List dense>
                            {noteForm.files.map((image, index) => (
                              <ListItem style={{ paddingLeft: 0 }} key={index}>
                                {image.mediaUrl ? (
                                  <ListItemAvatar>
                                    <Avatar
                                      variant="square"
                                      src={image.mediaUrl}
                                      style={{ width: 50, height: 50 }}
                                    />
                                  </ListItemAvatar>
                                ) : null}
                                <ListItemText
                                  primary={`# ${index + 1} - ${
                                    image.mediaType || image.type
                                  }`}
                                  secondary={
                                    image.mediaOriginalName || image.name
                                  }
                                />
                                <ListItemSecondaryAction>
                                  {image.mediaUrl ? (
                                    <IconButton
                                      edge="end"
                                      aria-label="view"
                                      href={image.mediaUrl || image.url}
                                      target="_blank"
                                    >
                                      <Visibility />
                                    </IconButton>
                                  ) : null}
                                  <IconButton
                                    edge="end"
                                    aria-label="delete"
                                    onClick={() => handleDeleteImage(index)}
                                  >
                                    <DeleteOutline style={{ color: "red" }} />
                                  </IconButton>
                                </ListItemSecondaryAction>
                              </ListItem>
                            ))}
                          </List>
                        ) : fileNoIds && fileNoIds.length > 0 ? (
                          <List dense>
                            {fileNoIds.map((image, index) => (
                              <ListItem style={{ paddingLeft: 0 }} key={index}>
                                {image.mediaUrl ? (
                                  <ListItemAvatar>
                                    <Avatar
                                      variant="square"
                                      src={image.mediaUrl}
                                      style={{ width: 50, height: 50 }}
                                    />
                                  </ListItemAvatar>
                                ) : null}
                                <ListItemText
                                  primary={`# ${index + 1} - ${
                                    image.mediaType || image.type
                                  }`}
                                  secondary={
                                    image.mediaOriginalName || image.name
                                  }
                                />
                                <ListItemSecondaryAction>
                                  {image.mediaUrl ? (
                                    <IconButton
                                      edge="end"
                                      aria-label="view"
                                      href={image.mediaUrl || image.url}
                                      target="_blank"
                                    >
                                      <Visibility />
                                    </IconButton>
                                  ) : null}
                                  <IconButton
                                    edge="end"
                                    aria-label="delete"
                                    onClick={() => handleDeleteImage(index)}
                                  >
                                    <DeleteOutline style={{ color: "red" }} />
                                  </IconButton>
                                </ListItemSecondaryAction>
                              </ListItem>
                            ))}
                          </List>
                        ) : (
                          <Typography variant="subtitle2">
                            {i18n.t("noteModal.form.noFiles")}
                          </Typography>
                        )}
                        <Button
                          variant="outlined"
                          color="primary"
                          component="label"
                          disabled={isSubmitting || isUploading}
                          style={{ marginTop: 5 }}
                        >
                          {i18n.t("noteModal.form.selectFiles")}
                          <input
                            type="file"
                            hidden
                            name="files"
                            id="files"
                            multiple
                            ref={fileInputRef}
                            onChange={() => handleUploadfiles()}
                          />
                        </Button>
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={handleClose}
                  color="secondary"
                  disabled={isSubmitting}
                  variant="outlined"
                >
                  {i18n.t("noteModal.buttons.cancel")}
                </Button>
                <Button
                  type="submit"
                  color="primary"
                  disabled={isSubmitting}
                  variant="contained"
                  className={classes.btnWrapper}
                >
                  {note
                    ? `${i18n.t("noteModal.buttons.okEdit")}`
                    : `${i18n.t("noteModal.buttons.okAdd")}`}
                  {isSubmitting && (
                    <CircularProgress
                      size={24}
                      className={classes.buttonProgress}
                    />
                  )}
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
    </div>
  );
};

export default NoteModal;
