import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import toastError from "../../errors/toastError";
import api from "../../services/api";
import { i18n } from "../../translate/i18n";

const ChatFlowsSelect = ({ selectedChatFlowsIds, onChange }) => {
  const [chatFlows, setChatFlows] = useState([]);

  useEffect(() => {
    (async () => {
      try {
        const { data } = await api.get("/chatFlows");
        setChatFlows(data);
      } catch (err) {
        toastError(err);
      }
    })();
  }, []);

  const handleChange = (e) => {
    onChange(e.target.value);
  };

  return (
    <div style={{ marginTop: 6 }}>
      <FormControl fullWidth margin="dense" variant="outlined">
        <InputLabel>{i18n.t("chatFlowsSelect.inputLabel")}</InputLabel>
        <Select
          labelWidth={80}
          value={selectedChatFlowsIds}
          onChange={handleChange}
          MenuProps={{
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left",
            },
            transformOrigin: {
              vertical: "top",
              horizontal: "left",
            },
            getContentAnchorEl: null,
          }}
        >
          <MenuItem value={null}>{i18n.t("chatFlowsSelect.noChatFlow")}</MenuItem>
          {chatFlows.map((chatFlow) => (
            <MenuItem key={chatFlow.id} value={chatFlow.id}>
              {chatFlow.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};

export default ChatFlowsSelect;
